import { Tooltip } from "@mui/material";
import React from "react";
import { LoadingIcon } from "./LoadingIcon";

function ButtonTable({
  onClick,
  color,
  Icon,
  tooltipTitle,
  withTooltip,
  placement,
  isLoading,
  size,
}) {
  return withTooltip ? (
    <Tooltip placement={placement} title={tooltipTitle}>
      <button
        type="button"
        disabled={isLoading}
        onClick={() => onClick()}
        className={`bg-gradient-to-r ${color} hover:bg-gradient-to-br focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-2.5 py-2.5 text-center  inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
      >
        {isLoading ? (
          <LoadingIcon color={"text-white"} size={`${size ?? "h-4 w-4"}`} />
        ) : (
          <Icon className={`${size ?? "h-4 w-4"} cursor-pointer`} />
        )}
      </button>
    </Tooltip>
  ) : (
    <button
      type="button"
      disabled={isLoading}
      onClick={() => onClick()}
      className={`bg-gradient-to-r ${color} hover:bg-gradient-to-br focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-2.5 py-2.5 text-center  inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
    >
      {isLoading ? (
        <LoadingIcon color={"text-white"} size={`${size ?? "h-4 w-4"}`} />
      ) : (
        <Icon className={`${size ?? "h-4 w-4"} cursor-pointer`} />
      )}
    </button>
  );
}

export default ButtonTable;
