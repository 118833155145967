// import { useGetAllProductsShipmentQuery } from "../../../services/shipmentApi";

// export const ListProductsToShip = ({ startDate, endDate }) => {
//   const {
//     data: shipmentProducts,
//     isLoading,
//     isSuccess,
//     isError,
//     error,
//   } = useGetAllProductsShipmentQuery({ startDate, endDate });
//   return (
//     <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 px-3 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
//       {isSuccess ? (
//         shipmentProducts.map((product, index) => (
//           <div
//             key={index}
//             className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow  dark:border-gray-900/5 flex flex-col justify-around"
//           >
//             <a href="#">
//               <img
//                 className="p-8 rounded-t-lg"
//                 src={product.image}
//                 alt="product image"
//               />
//             </a>
//             <div className="px-5 pb-5">
//               <a href="#">
//                 <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
//                   {product.name}
//                 </h5>
//               </a>
//               <div className="flex items-center justify-between">
//                 <span className=" text-sm leading-6 text-gray-900"></span>
//                 <a
//                   href="#"
//                   className="text-white bg-green-facile  focus:ring-4 focus:outline-none font-medium text-2xl   rounded-full h-16 w-16 text-center flex justify-center items-center"
//                 >
//                   {`x${product.qty}`}
//                 </a>
//               </div>
//               <div className="flex justify-between mt-2">
//                 <span className=" text-sm leading-6 text-gray-900">{`${product.sku}`}</span>
//                 <span className=" text-sm leading-6 text-gray-900">{`${
//                   product.cost ?? 0
//                 }€`}</span>
//               </div>
//             </div>
//           </div>
//         ))
//       ) : (
//         <></>
//       )}
//     </div>
//   );
// };
import React, { useState } from "react";
import { useGetAllProductsShipmentQuery } from "../../../services/shipmentApi";
import { exportToExcelBlob } from "../../../functions/export_to_excel";
import moment from "moment";
import { LoadingIcon } from "../../custom/LoadingIcon";
import { DocumentIcon } from "@heroicons/react/outline";

export const ListProductsToShip = ({ startDate, endDate }) => {
  const [exportingExcelProducts, setExportingExcelProducts] = useState(false);
  const {
    data: shipmentProducts,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllProductsShipmentQuery({ startDate, endDate });

  const onClickExportExcel = async () => {
    setExportingExcelProducts(true);
    const excelData = shipmentProducts.map((sp) => ({
      Prodotto: sp.name,
      //SKU: sp.sku,
      Quantità: sp.qty,
      Totale: sp.cost,
    }));

    await exportToExcelBlob(
      excelData,
      `prodotti_da_acquistare_${moment().format("YYYY_MM_DD_HH_mm_ss")}`
    );
    setExportingExcelProducts(false);
    //FileSaver.saveAs(data, fileName + ".xlsx");
  };

  return (
    <div className="">
      <div className="w-full flex justify-end mt-4">
        <button
          onClick={() => onClickExportExcel()}
          type="button"
          className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
        >
          Esporta XLSX
          {exportingExcelProducts ? (
            <LoadingIcon size={"h-4 w-4"} color={"text-white"} />
          ) : (
            <DocumentIcon className="h-4 w-4" />
          )}
        </button>
      </div>
      <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
        {isSuccess ? (
          shipmentProducts.map((product, index) => (
            <div
              key={index}
              className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow flex p-4 flex-wrap"
            >
              <img
                className="w-24 h-24 object-contain"
                src={product.image}
                alt="product image"
              />
              <div className="flex flex-col justify-between ml-4 flex-grow">
                <div className="text-sm text-gray-500">{product.sku}</div>
                <div className="text-lg font-semibold text-gray-900">{`${(
                  product.cost ?? 0
                ).toFixed(2)}€`}</div>
              </div>
              {product.qty > 0 && (
                <div className="self-end">
                  <span className="inline-flex items-center justify-center px-2 py-1 text-lg font-bold leading-none text-white bg-green-600 rounded-full">
                    {`x${product.qty}`}
                  </span>
                </div>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
