import moment from "moment";
import { ListShipment } from "../components/shipment/ListShipment";
import { useEffect, useState } from "react";
import DropDown from "../components/order/NewShopifyOrderList/DropDown";
import DropDownStore from "../components/order/DropDownStore";
import { DropDownCustomer } from "../components/customer/DropDownCustomer";
import { useSelector } from "react-redux";
import { SearchIcon } from "@heroicons/react/outline";
import {
  useGetAllShipmentsMutation,
  useGetShipmentPagesMutation,
} from "../services/shipmentApi";
import DropDownSpedisciOnlineStatus from "../components/shipment/DropDownSpedisciOnlineStatus";
import PrintAllButton from "../components/shipment/PrintAllButton";
import Select from "react-select";
import { useGetAllCustomerQuery } from "../services/customerApi";
import { useGetAllStoreQuery } from "../services/shopifyApi";
import ToggleButton from "../components/custom/ToggleButton";

const options = [
  { id: 0, name: "Tutto" },
  { id: 1, name: "Si" },
  { id: 2, name: "No" },
];

export const ShipmentPage = () => {
  const auth = useSelector((state) => state.auth);
  const [showOrderProducts, setShowOrderProducts] = useState(false);
  const [shipmentsToPrint, setShipmentsToPrint] = useState([]);
  const [filterCashOnDelivery, setFilterCashOnDelivery] = useState(options[0]);
  const [filterPaidOrder, setFilterPaidOrder] = useState(options[0]);
  const [filterIsPrinted, setFilterIsPrinted] = useState(options[0]);
  const [filterStore, setFilterStore] = useState();
  const [filterCustomer, setFilterCustomer] = useState();
  const [filterShipmentStatus, setFilterShipmentStatus] = useState();
  const [searchText, setSearchText] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(
    moment().add(-30, "days").format("yyyy-MM-DD")
  );
  const [filterEndDate, setFilterEndDate] = useState(
    moment().format("yyyy-MM-DD")
  );
  const [page, setPage] = useState(1);
  const [rowsPage, setRowsPage] = useState(20);

  var {
    stores,
    isLoading: isLoadingStores,
    isSuccess: isSuccessStores,
    isError: isErrorStores,
    error: errorStores,
  } = useGetAllStoreQuery(undefined, {
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      var stores = data?.map((store) => ({
        value: store,
        label:
          (store?.aliasStore ?? "") == ""
            ? store?.storeName
            : store?.aliasStore,
      }));

      return {
        stores,
        isLoading,
        isSuccess,
        isError,
        error,
      };
    },
  });

  var {
    customers,
    isLoading: loadingCustomers,
    isSuccess: successCustomers,
    isError: errorCustomersEvent,
    error: errorCustomersText,
  } = useGetAllCustomerQuery("%20", {
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      var customers = data?.map((cus) => ({
        value: cus,
        label: `${cus?.name} ${cus?.surname}`,
      }));
      return {
        customers,
        isLoading,
        isSuccess,
        isError,
        error,
      };
    },
  });

  const [
    getAllShipments,
    { data: shipments, isLoading, isSuccess, isError, error },
  ] = useGetAllShipmentsMutation();

  const [getShipmentPages, { data: shipmentsLength }] =
    useGetShipmentPagesMutation();

  var filter = {
    searchText,
    isCashOnDelivery:
      filterCashOnDelivery.id == 1
        ? true
        : filterCashOnDelivery.id == 2
        ? false
        : undefined,
    isPaidOrder:
      filterPaidOrder.id == 1
        ? true
        : filterPaidOrder.id == 2
        ? false
        : undefined,
    isPrinted:
      filterIsPrinted.id == 1
        ? true
        : filterIsPrinted.id == 2
        ? false
        : undefined,
    dateRange: { startDate: filterStartDate, endDate: filterEndDate },
    shopifyStoreId: filterStore?.value?.id ?? 0,
    codfacileCustomer: filterCustomer?.value?.id ?? 0,
    shipmentStatus: filterShipmentStatus?.name,
  };

  const getShipments = () => {
    getShipmentPages({ filter });
    getAllShipments({
      page,
      rowsPage,
      filter: filter,
    });
  };

  useEffect(() => {
    getShipments();
  }, [
    page,
    rowsPage,
    filterCashOnDelivery,
    filterPaidOrder,
    filterIsPrinted,
    filterStore,
    filterCustomer,
    filterStartDate,
    filterEndDate,
    filterShipmentStatus,
  ]);

  return (
    <main className="px-4">
      <div className="flex-col">
        <div className="flex flex-wrap gap-2 items-end mb-2">
          <div className="flex gap-2 mt-2 flex-wrap items-end">
            <div>
              <DropDown
                label={"Contrassegno"}
                items={options}
                selectedItem={filterCashOnDelivery}
                onChange={setFilterCashOnDelivery}
              />
            </div>
            <div>
              <DropDown
                label={"Pagati"}
                items={options}
                selectedItem={filterPaidOrder}
                onChange={setFilterPaidOrder}
              />
            </div>
            {auth.roles.includes(1) ? (
              <div>
                <DropDown
                  label={"Stampati"}
                  items={options}
                  selectedItem={filterIsPrinted}
                  onChange={setFilterIsPrinted}
                />
              </div>
            ) : (
              <></>
            )}
            <div>
              <DropDownSpedisciOnlineStatus
                defaultValue={{ id: 0, name: "Tutti" }}
                onSelectedStatus={(status) => setFilterShipmentStatus(status)}
              />
            </div>
            <div className="flex-col ">
              <span className="block text-sm font-medium leading-6 text-gray-900">
                Negozio
              </span>
              {isSuccessStores && (
                <Select
                  placeholder={"Cerca Negozio..."}
                  isSearchable={true}
                  options={stores}
                  onChange={setFilterStore}
                  isClearable={true}
                />
              )}
            </div>
            {auth.roles.includes(1) ? (
              <div className="flex-col">
                <span className="block text-sm font-medium leading-6 text-gray-900">
                  Cliente
                </span>
                <Select
                  placeholder={"Cerca Cliente..."}
                  isSearchable={true}
                  options={customers}
                  onChange={setFilterCustomer}
                  isClearable={true}
                />
              </div>
            ) : (
              <></>
            )}

            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Data Inizio
              </label>
              <input
                className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                type="date"
                value={filterStartDate}
                onChange={(e) => setFilterStartDate(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Data Fine
              </label>
              <input
                className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                type="date"
                value={filterEndDate}
                onChange={(e) => setFilterEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center gap-2 flex-1">
            <input
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") getShipments();
              }}
              type="text"
              name="name"
              id="name"
              className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              placeholder="Cerca nelle spedizioni"
            />
            <button
              onClick={(e) => getShipments()}
              type="button"
              className="rounded-xl bg-green-600 p-2 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600
           bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm  text-center "
            >
              <SearchIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div
          className={`flex items-center ${
            auth.roles.find((r) => r == 1) ? "justify-between" : "justify-end"
          }`}
        >
          <ToggleButton
            enabled={showOrderProducts}
            onChangeEvent={(e) => {
              setShowOrderProducts(e);
            }}
            text={"Mostra Prodotti nell'ordine"}
          />
          {auth.roles.includes(1) && (
            <div className="flex justify-end">
              <PrintAllButton
                trackingNumbers={shipmentsToPrint
                  //?.filter((s) => s.is_to_print)
                  ?.map((ship) => ship.tracking_number)}
              />
            </div>
          )}
        </div>
      </div>
      <ListShipment
        showOrderProducts={showOrderProducts}
        shipmentsToPrint={shipmentsToPrint}
        setShipmentsToPrint={setShipmentsToPrint}
        shipments={shipments}
        shipmentsLength={shipmentsLength}
        page={page}
        setRowsPage={setRowsPage}
        rowsPage={rowsPage}
        setPage={setPage}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        error={error}
      />
    </main>
  );
};
