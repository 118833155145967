import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ArrowDownIcon } from "@heroicons/react/solid";
import { LoadingIcon } from "../../custom/LoadingIcon";
import { useGetShippingPriceListQuery } from "../../../services/shippingPriceListApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropDownShippingPriceList({ selected, handleChange }) {
  const {
    data: shippingPriceLists,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetShippingPriceListQuery(undefined, {
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      return isSuccess
        ? {
            data: [undefined, ...data],
            isLoading,
            isSuccess,
            isError,
            error,
          }
        : [];
    },
  });

  return isLoading ? (
    <LoadingIcon color={"text-gray-900"} />
  ) : isSuccess ? (
    <Listbox
      value={selected}
      onChange={(shippingPriceList) => handleChange(shippingPriceList)}
    >
      {({ open }) => (
        <div>
          <Listbox.Label className="block text-sm text-left font-medium leading-6 text-gray-900 ">
            Listino Spedizione
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button
              className={classNames(
                selected == undefined ? "py-4" : "py-1.5",
                "relative w-full cursor-default rounded-md bg-white  pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              )}
            >
              <span className="block truncate">
                {selected == undefined ? "" : `${selected.code}`}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ArrowDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {shippingPriceLists.map((shippingPriceList) => (
                  <Listbox.Option
                    key={
                      shippingPriceList == undefined ? 0 : shippingPriceList.id
                    }
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        shippingPriceList == undefined ? "py-4" : "py-2",
                        "relative cursor-default select-none  pl-3 pr-9 text-left"
                      )
                    }
                    value={shippingPriceList}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {shippingPriceList == undefined
                            ? ""
                            : `${shippingPriceList.code}`}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  ) : isError ? (
    <p>{error}</p>
  ) : (
    <></>
  );
}
