import React, { useEffect, useState } from "react";
import PopupDialog from "../custom/PopupDialog";
import RadioGroup from "../custom/RadioGroup";
import RadioGroupCard from "../custom/RadioGroupCard";
import {
  NextActionEnum,
  NextActionRadioButtons,
} from "../../enums/NextActionEnum";
import { LoadingIcon } from "../custom/LoadingIcon";
import { CheckCircleIcon } from "@heroicons/react/outline";
import {
  useAddShipmentHoldMutation,
  useGetShipmentOnHoldQuery,
  useUpdateShipmentHoldMutation,
} from "../../services/shipmentApi";
import moment from "moment";
import { InputField } from "../RegistrationForm";
import { useSelector } from "react-redux";
import {
  HandlingStatusEnum,
  HandlingStatusRadioButtons,
} from "../../enums/HandlingStatusEnum";
import { XIcon } from "@heroicons/react/solid";

function ButtonGestioneGiacenza({ shipmentId }) {
  const [openPopup, setOpenPopup] = useState(false);
  const {
    data: shipmentOnHoldDb,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetShipmentOnHoldQuery(shipmentId);

  // Usa useEffect per rifetchare quando openPopup diventa true
  useEffect(() => {
    if (openPopup) {
      refetch();
    }
  }, [openPopup, refetch]);

  return (
    <>
      <PopupGestioneUser
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        shipmentOnHoldDb={shipmentOnHoldDb}
        isLoading={isLoading}
        isSuccess={isSuccess}
        shipmentId={shipmentId}
        refetch={refetch}
      />

      <div className="flex flex-wrap gap-2">
        {shipmentOnHoldDb == null ? (
          <button
            className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => setOpenPopup(!openPopup)}
          >
            Gestisci Giacenza
          </button>
        ) : (
          <button
            className="rounded-full bg-green-200 px-2.5 py-1 text-xs font-semibold text-green-900 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-green-50 uppercase"
            onClick={() => setOpenPopup(!openPopup)}
          >
            {shipmentOnHoldDb.nextAction}
          </button>
        )}
        {shipmentOnHoldDb?.handlingStatus != null && (
          <div
            className={`${
              shipmentOnHoldDb?.handlingStatus ==
              HandlingStatusEnum["Svincolo Non Possibile"]
                ? "bg-red-600 text-red-100"
                : shipmentOnHoldDb?.handlingStatus ==
                  HandlingStatusEnum["Svincolo Effettuato"]
                ? "bg-green-600 text-green-100"
                : "bg-yellow-600 text-yellow-100"
            }  uppercase font-bold rounded-full px-2 py-1 w-fit`}
          >
            {HandlingStatusRadioButtons[shipmentOnHoldDb.handlingStatus].name}
          </div>
        )}
      </div>
    </>
  );
}

const PopupGestioneUser = ({
  openPopup,
  setOpenPopup,
  shipmentOnHoldDb,
  isLoading,
  isSuccess,
  shipmentId,
  refetch,
}) => {
  const auth = useSelector((state) => state.auth);
  const [shipmentHold, setShipmentHold] = useState(null);

  const [handlingDate, setHandlingDate] = useState(
    moment().format("yyyy-MM-DD")
  );

  const [
    addShipmentHold,
    {
      isLoading: addingShipmentHold,
      isSuccess: addedShipmentHold,
      isError: errorAddingEvent,
      error: errorAddingText,
    },
  ] = useAddShipmentHoldMutation();

  const [
    updateShipmentHold,
    {
      isLoading: updatingShipmentHold,
      isSuccess: updatedShipmentHold,
      isError: errorUpdatingEvent,
      error: errorUpdatingText,
    },
  ] = useUpdateShipmentHoldMutation();

  const onConfirmClick = () => {
    var shipHold = {
      ...shipmentHold,
      handlingDate: handlingDate,
      shipmentId: shipmentId,
    };
    if (shipmentHold?.id == null) {
      shipHold = {
        ...shipHold,
        nextAction: shipmentHold?.nextAction ?? NextActionRadioButtons[0].name,
        handlingStatus: HandlingStatusEnum["In Lavorazione"],
      };
      addShipmentHold({ shipmentHold: shipHold }).then((res) => {
        if (res.error) {
          return;
        }
        setShipmentHold(res.data[0]);
        setOpenPopup(false);
        refetch();
      });
    } else {
      updateShipmentHold({ shipmentHold: shipHold }).then((res) => {
        if (res.error) {
          return;
        }
        setShipmentHold(res.data[0]);
        setOpenPopup(false);
        refetch();
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setShipmentHold(shipmentOnHoldDb);
    }
  }, [isLoading]);

  return (
    openPopup && (
      <PopupDialog open={openPopup} setOpen={setOpenPopup}>
        <h1 className="text-lg mb-2">
          Scegli l'operazione per lo svincolo della giacenza
        </h1>
        <div className="mb-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Data Svincolo
          </label>
          <input
            disabled={shipmentHold?.handlingDate != null}
            className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3 w-full"
            type="date"
            value={handlingDate}
            onChange={(e) => setHandlingDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <InputField
            type="textarea"
            disabled={!auth.roles.includes(2)}
            label={"Nota Svincolo"}
            onChange={(e) =>
              setShipmentHold({
                ...shipmentHold,
                nextActionNote: e.target.value,
              })
            }
            value={shipmentHold?.nextActionNote ?? ""}
          />
        </div>

        {(shipmentOnHoldDb?.nextAction ?? "") == "" ? (
          <RadioGroupCard
            selectedValue={NextActionRadioButtons.find(
              (nrb) =>
                nrb.name ===
                (shipmentHold?.nextAction ?? NextActionRadioButtons[0].name)
            )}
            items={NextActionRadioButtons}
            onChange={(item) => {
              setShipmentHold({ ...shipmentHold, nextAction: item.name });
            }}
          />
        ) : (
          <>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Operazione Richiesta:
            </label>
            <div className="flex justify-between items-center gap-2">
              <div className="bg-green-600 w-full text-green-100 uppercase font-bold rounded-md px-2 py-1 ">
                {`${shipmentHold?.nextAction ?? ""}`}
              </div>
              {(shipmentOnHoldDb?.nextAction ?? "") == "" && (
                <div
                  onClick={() =>
                    setShipmentHold({ ...shipmentHold, nextAction: null })
                  }
                  className="flex gap-2 text-white bg-red-700 pl-2 pr-1 py-1 rounded-md uppercase font-bold cursor-pointer"
                >
                  Annulla
                  <XIcon className="h-6 w-6 text-white" />
                </div>
              )}
            </div>
          </>
        )}
        <div className="mt-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Stato Svincolo:
          </label>
          {shipmentHold?.nextAction != null && auth.roles.includes(1) ? (
            <RadioGroupCard
              selectedValue={
                shipmentHold?.handlingStatus != null
                  ? HandlingStatusRadioButtons.find(
                      (na) => na?.id == shipmentHold.handlingStatus
                    )
                  : HandlingStatusRadioButtons[0]
              }
              items={HandlingStatusRadioButtons}
              onChange={(item) => {
                setShipmentHold({ ...shipmentHold, handlingStatus: item?.id });
              }}
            />
          ) : (
            <div
              className={`${
                shipmentHold?.handlingStatus ==
                HandlingStatusEnum["Svincolo Non Possibile"]
                  ? "bg-red-600 text-red-100"
                  : shipmentHold?.handlingStatus ==
                    HandlingStatusEnum["Svincolo Effettuato"]
                  ? "bg-green-600 text-green-100"
                  : "bg-yellow-600 text-yellow-100"
              } w-full  uppercase font-bold rounded-md px-2 py-1 `}
            >
              {shipmentHold?.handlingStatus == null
                ? "da lavorare"
                : `${
                    HandlingStatusRadioButtons[shipmentHold.handlingStatus].name
                  }`}
            </div>
          )}
        </div>
        <div className="mt-2">
          {shipmentHold?.nextAction ==
            NextActionEnum["riconsegna al nuovo destinatario"] && (
            <InputField
              disabled={(shipmentOnHoldDb?.nextAction ?? "") != ""}
              label={"Inserisci nuovo indirizzo"}
              onChange={(e) =>
                setShipmentHold({
                  ...shipmentHold,
                  nextActionAddress: e.target.value,
                })
              }
              value={shipmentHold.nextActionAddress}
            />
          )}
        </div>
        {auth.roles.includes(1) && (
          <>
            <InputField
              type={"textarea"}
              label={"Motivazione (se necessaria)"}
              onChange={(e) =>
                setShipmentHold({
                  ...shipmentHold,
                  handlingStatusMotivation: e.target.value,
                })
              }
              value={shipmentHold?.handlingStatusMotivation ?? ""}
            />

            <InputField
              disabled={
                shipmentHold?.handlingStatus ==
                HandlingStatusEnum["In Lavorazione"]
              }
              type={"number"}
              label={"Costo Servizio"}
              onChange={(e) =>
                setShipmentHold({
                  ...shipmentHold,
                  handlingCost: e.target.value,
                })
              }
              value={shipmentHold?.handlingCost}
            />
          </>
        )}
        <div className="mt-2">
          {!auth.roles.includes(1) &&
            (shipmentHold?.handlingStatusMotivation ?? "") !== "" && (
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Motivazione Stato Svincolo:
                </label>
                <p className="rounded-md border px-2 py-1">
                  {shipmentHold?.handlingStatusMotivation ?? ""}
                </p>
              </div>
            )}
        </div>
        <div className="flex justify-between mt-4 items-center">
          <div>
            {(shipmentHold?.handlingCost ?? 0) > 0
              ? `Costo Servizio: ${shipmentHold?.handlingCost ?? 0}€`
              : ""}
          </div>
          <ButtonGreen
            onClick={() => onConfirmClick()}
            text={
              shipmentHold?.id == null
                ? "Conferma Svincolo"
                : "Aggiorna Svincolo"
            }
            isLoading={false}
            CustomIcon={CheckCircleIcon}
          />
        </div>
      </PopupDialog>
    )
  );
};

const PopupGestioneAdmin = ({
  openPopup,
  setOpenPopup,
  shipmentOnHoldDb,
  shipmentId,
  refetch,
}) => {
  const auth = useSelector((state) => state.auth);
  const [shipmentHold, setShipmentHold] = useState(shipmentOnHoldDb);

  const [handlingDate, setHandlingDate] = useState(
    moment().format("yyyy-MM-DD")
  );

  const [
    addShipmentHold,
    {
      isLoading: addingShipmentHold,
      isSuccess: addedShipmentHold,
      isError: errorAddingEvent,
      error: errorAddingText,
    },
  ] = useAddShipmentHoldMutation();

  const [
    updateShipmentHold,
    {
      isLoading: updatingShipmentHold,
      isSuccess: updatedShipmentHold,
      isError: errorUpdatingEvent,
      error: errorUpdatingText,
    },
  ] = useUpdateShipmentHoldMutation();

  const onConfirmClick = () => {
    var shipHold = {
      ...shipmentHold,
      handlingDate: handlingDate,
      shipmentId: shipmentId,
    };
    if (shipmentHold?.id == null) {
      shipHold = {
        ...shipHold,
        handlingStatus: HandlingStatusEnum["In Lavorazione"],
      };
      addShipmentHold({ shipmentHold: shipHold }).then((res) => {
        if (res.error) {
          return;
        }
        setShipmentHold(res.data[0]);
        setOpenPopup(false);
        refetch();
      });
    } else {
      updateShipmentHold({ shipmentHold: shipHold }).then((res) => {
        if (res.error) {
          return;
        }
        setShipmentHold(res.data[0]);
        setOpenPopup(false);
        refetch();
      });
    }
  };

  return (
    openPopup && (
      <PopupDialog open={openPopup} setOpen={setOpenPopup}>
        <h1 className="text-lg mb-2">
          Scegli l'operazione per lo svincolo della giacenza
        </h1>
        <div className="mb-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Data Svincolo
          </label>
          <input
            disabled={shipmentHold?.handlingDate != null}
            className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3 w-full"
            type="date"
            value={handlingDate}
            onChange={(e) => setHandlingDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <InputField
            type="textarea"
            disabled={!auth.roles.includes(2)}
            label={"Nota Svincolo"}
            onChange={(e) =>
              setShipmentHold({
                ...shipmentHold,
                nextActionNote: e.target.value,
              })
            }
            value={shipmentHold?.nextActionNote ?? ""}
          />
        </div>

        {shipmentHold?.nextAction == null ? (
          <RadioGroupCard
            selectedValue={NextActionRadioButtons.find(
              (nrb) =>
                nrb.name ===
                (shipmentHold?.nextAction ?? NextActionRadioButtons[0].name)
            )}
            items={NextActionRadioButtons}
            onChange={(item) => {
              setShipmentHold({ ...shipmentHold, nextAction: item.name });
            }}
          />
        ) : (
          <>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Operazione Richiesta:
            </label>
            <div className="flex justify-between items-center gap-2">
              <div className="bg-green-600 w-full text-green-100 uppercase font-bold rounded-md px-2 py-1 ">
                {`${shipmentHold?.nextAction ?? ""}`}
              </div>
              {(shipmentOnHoldDb?.nextAction ?? "") == "" && (
                <div
                  onClick={() =>
                    setShipmentHold({ ...shipmentHold, nextAction: null })
                  }
                  className="flex gap-2 text-white bg-red-700 pl-2 pr-1 py-1 rounded-md uppercase font-bold cursor-pointer"
                >
                  Annulla
                  <XIcon className="h-6 w-6 text-white" />
                </div>
              )}
            </div>
          </>
        )}
        <div className="mt-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Stato Svincolo:
          </label>
          {shipmentHold?.nextAction != null && auth.roles.includes(1) ? (
            <RadioGroupCard
              selectedValue={
                shipmentHold?.handlingStatus != null
                  ? HandlingStatusRadioButtons.find(
                      (na) => na?.id == shipmentHold.handlingStatus
                    )
                  : HandlingStatusRadioButtons[0]
              }
              items={HandlingStatusRadioButtons}
              onChange={(item) => {
                setShipmentHold({ ...shipmentHold, handlingStatus: item?.id });
              }}
            />
          ) : (
            <div
              className={`${
                shipmentHold?.handlingStatus ==
                HandlingStatusEnum["Svincolo Non Possibile"]
                  ? "bg-red-600 text-red-100"
                  : shipmentHold?.handlingStatus ==
                    HandlingStatusEnum["Svincolo Effettuato"]
                  ? "bg-green-600 text-green-100"
                  : "bg-yellow-600 text-yellow-100"
              } w-full  uppercase font-bold rounded-md px-2 py-1 `}
            >
              {shipmentHold?.handlingStatus == null
                ? "da lavorare"
                : `${
                    HandlingStatusRadioButtons[shipmentHold.handlingStatus].name
                  }`}
            </div>
          )}
        </div>
        <div className="mt-2">
          {shipmentHold?.nextAction ==
            NextActionEnum["riconsegna al nuovo destinatario"] && (
            <InputField
              disabled={(shipmentOnHoldDb?.nextAction ?? "") != ""}
              label={"Inserisci nuovo indirizzo"}
              onChange={(e) =>
                setShipmentHold({
                  ...shipmentHold,
                  nextActionAddress: e.target.value,
                })
              }
              value={shipmentHold.nextActionAddress}
            />
          )}
        </div>
        {auth.roles.includes(1) && (
          <>
            <InputField
              type={"textarea"}
              label={"Motivazione (se necessaria)"}
              onChange={(e) =>
                setShipmentHold({
                  ...shipmentHold,
                  handlingStatusMotivation: e.target.value,
                })
              }
              value={shipmentHold?.handlingStatusMotivation ?? ""}
            />

            <InputField
              disabled={
                shipmentHold?.handlingStatus !==
                HandlingStatusEnum["In Lavorazione"]
              }
              type={"number"}
              label={"Costo Servizio"}
              onChange={(e) =>
                setShipmentHold({
                  ...shipmentHold,
                  handlingCost: e.target.value,
                })
              }
              value={shipmentHold?.handlingCost}
            />
          </>
        )}
        <div className="mt-2">
          {!auth.roles.includes(1) &&
            (shipmentHold?.handlingStatusMotivation ?? "") !== "" && (
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Motivazione Stato Svincolo:
                </label>
                <p className="rounded-md border px-2 py-1">
                  {shipmentHold?.handlingStatusMotivation ?? ""}
                </p>
              </div>
            )}
        </div>
        <div className="flex justify-between mt-4 items-center">
          <div>
            {(shipmentHold?.handlingCost ?? 0) > 0
              ? `Costo Servizio: ${shipmentHold?.handlingCost ?? 0}€`
              : ""}
          </div>
          <ButtonGreen
            onClick={() => onConfirmClick()}
            text={
              shipmentHold?.id == null
                ? "Conferma Svincolo"
                : "Aggiorna Svincolo"
            }
            isLoading={false}
            CustomIcon={CheckCircleIcon}
          />
        </div>
      </PopupDialog>
    )
  );
};

const ButtonGreen = ({ onClick, text, isLoading, CustomIcon }) => {
  return (
    <button
      onClick={() => onClick()}
      type="button"
      className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
    >
      {text}
      {isLoading ? (
        <LoadingIcon size={"h-4 w-4"} color={"text-white"} />
      ) : (
        <CustomIcon className="h-4 w-4" />
      )}
    </button>
  );
};

export default ButtonGestioneGiacenza;
