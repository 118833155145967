import { userApi } from "./userApi";

const promotionApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    checkAliasCode: builder.query({
      query: (aliasCode) => `promotion/check-alias-code/${aliasCode}`,
    }),
    getAllReferral: builder.query({
      query: () => `promotion/get-all-referrals`,
      providesTags: ["PromotionCode"],
    }),
    getAllPromotion: builder.query({
      query: () => `promotion/get-all`,
      providesTags: ["PromotionCode"],
    }),
    updatePromotion: builder.mutation({
      query: (promotion) => ({
        url: `promotion/update-one`,
        method: "PUT",
        body: { promotion },
      }),
      invalidatesTags: ["PromotionCode"],
    }),
    insertPromotion: builder.mutation({
      query: (promotion) => ({
        url: `promotion/insert-one`,
        method: "POST",
        body: { promotion },
      }),
      invalidatesTags: ["PromotionCode"],
    }),
    deletePromotion: builder.mutation({
      query: (promotion) => ({
        url: `promotion/delete-one`,
        method: "DELETE",
        body: { promotion },
      }),
      invalidatesTags: ["PromotionCode"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeletePromotionMutation,
  useGetAllPromotionQuery,
  useInsertPromotionMutation,
  useUpdatePromotionMutation,
  useGetAllReferralQuery,
  useCheckAliasCodeQuery,
} = promotionApi;
