import React from "react";

function ListTable({ children, className }) {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table
        className={`w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ${className}`}
      >
        {children}
      </table>
    </div>
  );
}

export default ListTable;
