import { userApi } from "./userApi";

const shopifyApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStore: builder.query({
      query: () => `shopify-api/all-stores`,
    }),
    getAllWebhooks: builder.query({
      query: (storeName) => `shopify-app-keys/all-webhooks/${storeName}`,
    }),
    createWebhooks: builder.mutation({
      query: ({ key }) => ({
        url: `shopify-app-keys/create-webhooks`,
        method: "POST",
        body: { key },
      }),
    }),
    updateOrderTags: builder.mutation({
      query: ({ orderId, tag }) => ({
        url: `shopify-api/update-order-tags`,
        method: "PUT",
        body: { orderId, tag },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllStoreQuery,
  useCreateWebhooksMutation,
  useGetAllWebhooksQuery,
  useLazyGetAllWebhooksQuery,
  useUpdateOrderTagsMutation,
} = shopifyApi;
