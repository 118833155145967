import { io } from "socket.io-client";
import { userApi } from "./userApi";

const spedisciOnlineApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    createLDV: builder.mutation({
      query: (orders) => ({
        url: `/spedisci-online-api/create-ldv`,
        method: "POST",
        body: orders,
      }),
      //invalidatesTags: ["ShopifyOrders", "Shipments"],
    }),
  }),
  overrideExisting: false,
});

export const { useCreateLDVMutation } = spedisciOnlineApi;
