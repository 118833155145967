function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleCard({ title, subtitle, initials }) {
  return (
    <li className="col-span-1 flex rounded-md shadow-sm">
      <div className="flex flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <a href="#" className="font-medium text-gray-900 hover:text-gray-600">
            {title}
          </a>
          <p className="text-gray-500">{subtitle}</p>
        </div>
        <div className="flex-shrink-0 pr-2">
          {/* <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </button> */}
        </div>
      </div>
    </li>
  );
}
