import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RowShopifyOrder from "./RowShopifyOrder";
import { LoadingIcon } from "../../custom/LoadingIcon";
import { usePayProductsMutation } from "../../../services/stripeApi";
import { useUpdateFulfillmentRequestMutation } from "../../../services/orderApi";
import ModalConfirmationRequest from "../../custom/ModalConfirmationRequest";
import SimpleNotification from "../../SimpleNotification";

function ListShopifyOrder({
  getAllOrders,
  showOrderProducts,
  orders,
  selectedOrder,
  setSelectedOrder,
  ordersToPay,
  setOrdersToPay,
  printingMode,
  handleAddItem,
  handleItemToPrint,
  selectedOrderToPrint,
  isLoading,
  isSuccess,
  isError,
  error,
}) {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [payProducts] = usePayProductsMutation();
  const [confirmUpdateFulfillment, setConfirmUpdateFulfillment] =
    useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [selectedOrderRow, setSelectedOrderRow] = useState(orders[0] ?? []);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSuccess, setNotificationSuccess] = useState(false);

  const [
    updateFulfillment,
    {
      data: dataUpdateFulfillment,
      isLoading: loadingUpdateFulfillment,
      isSuccess: successUpdateFulfillment,
      isError: errorUpdateFulfillmentEvent,
      error: errorUpdateFulfillmentText,
    },
  ] = useUpdateFulfillmentRequestMutation();

  const onAddToPay = async (order) => {
    if (ordersToPay.find((op) => op.id == order.id)) {
      setOrdersToPay(ordersToPay.filter((op) => op.id !== order.id));
      return;
    }
    setOrdersToPay([...ordersToPay, order]);
  };

  const onClickPay = async (order) => {
    if ((order?.amount_paid ?? 0) !== 0) {
      setNotificationSuccess(true);
      setNotificationMessage("Ordine Già Pagato");
      setShowNotification(true);
      return;
    }
    await payProducts({
      orderId: order.id,
      // storeName: order.store_name,
      // orderNumber: order.order_number,
    }).then((response) => {
      if (response.error) {
        setNotificationSuccess(false);
        setNotificationMessage(response.error.data.message);
        setShowNotification(true);
        return;
      }
      window.location.href = response.data;
    });
    // setOrderToPay(order);
    // setOpenCheckoutPopup(true);
  };

  const onEditFulfillmentRequest = async (order) => {
    setConfirmUpdateFulfillment(true);
    setSelectedOrderRow(order);
    setConfirmMessage(
      `Desideri cancellare la richiesta di evasione per l'ordine #${
        order.order_number
      } dello store '${
        (order?.alias_store ?? "") == "" ? order.store_name : order.alias_store
      }' ?`
    );
  };

  const handleDeleteConfirmation = async () => {
    await updateFulfillment({
      orders: [selectedOrderRow],
      toFulfill: false,
      fulfillmentStatusId: undefined,
    }).then((res) => {
      if (res.error) {
        setNotificationSuccess(false);
        setNotificationMessage(res.error.data.message);
        setShowNotification(true);
        return;
      }
      setNotificationSuccess(true);
      setNotificationMessage(
        "Richiesta Stato Evasione Eliminata Correttamente"
      );
      setShowNotification(true);
      getAllOrders();
    });
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <SimpleNotification
        message={notificationMessage}
        show={showNotification}
        setShow={setShowNotification}
        success={notificationSuccess}
      />
      <ModalConfirmationRequest
        handleConfirm={handleDeleteConfirmation}
        showModal={confirmUpdateFulfillment}
        setShowModal={setConfirmUpdateFulfillment}
        message={confirmMessage}
      />
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <span className="sr-only">Edit</span>
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-green-facile focus:ring-green-facile"
                onChange={(e) =>
                  e.target.checked
                    ? setSelectedOrder(
                        orders.filter(
                          (ord) =>
                            ord.tracking_number == null &&
                            (ord?.fullfilment_status ?? "") == ""
                        )
                      )
                    : setSelectedOrder([])
                }
              />
            </th>
            {/* {auth.roles.find((role) => role == 1) ? ( */}
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <span className="sr-only">Edit</span>
            </th>

            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
            >
              Data
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
            >
              Ordine
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Stato di Evasione
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Cliente
            </th>
            {auth.roles.find((role) => role == 1) ? (
              <th
                scope="col"
                className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
              >
                Cliente CODFacile
              </th>
            ) : (
              <></>
            )}
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Stato Spedizione
            </th>
            {/* <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Prezzo vendita Prodotto
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Prezzo Spedizione
            </th> */}
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              COD
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Costo Prodotti
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Costo Spedizione
            </th>
            {/* <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Profitto
            </th> */}
            {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3"></th> */}
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3"></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <LoadingIcon className="h-4 w-4" />
          ) : isSuccess ? (
            orders.map((order) => (
              <RowShopifyOrder
                onAddToPay={onAddToPay}
                ordersToPay={ordersToPay}
                onEditFulfillmentRequest={onEditFulfillmentRequest}
                showOrderProducts={showOrderProducts}
                auth={auth}
                navigate={navigate}
                onClickPay={onClickPay}
                key={order.id}
                order={order}
                selectedOrder={selectedOrder}
                printingMode={printingMode}
                handleAddItem={handleAddItem}
                handleItemToPrint={handleItemToPrint}
                selectedOrderToPrint={selectedOrderToPrint}
              />
            ))
          ) : isError ? (
            <span>{error.data}</span>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ListShopifyOrder;
