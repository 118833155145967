import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import React, { Fragment, useRef, useState } from "react";
import { useUpdateShipmentOrderByCsvMutation } from "../services/shipmentApi";
import { toBase64 } from "../functions/file";
import { LoadingIcon } from "./custom/LoadingIcon";
import BadgeErrorMessage from "./custom/BadgeErrorMessage";
import BadgeSuccessMessage from "./custom/BadgeSuccessMessage";

function PopupSelectStore({ show, setShow }) {
  const [updateShipmentOrderByCsv, { isLoading, isSuccess, isError, error }] =
    useUpdateShipmentOrderByCsvMutation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [storeName, setStoreName] = useState("");

  const LoadCsv = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleConfirmClick = async (e) => {
    if (!selectedFile) {
      alert("Caricare un file csv ");
      return;
    }
    e.preventDefault();
    const csvBase64 = await toBase64(selectedFile);
    await updateShipmentOrderByCsv({
      storeName,
      csvBase64,
    });
  };

  const cancelButtonRef = useRef(null);
  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <TransitionChild
            as="div"
            onClick={() => setShow(false)}
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          ></TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="customer-codfacile"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Inserisci lo store
                    </label>
                    <div className="text-sm"></div>
                  </div>
                  <div className="mt-2">
                    <input
                      required
                      onChange={(e) => setStoreName(e.target.value)}
                      id="customer-codfacile"
                      name="customer-codfacile"
                      type="customer-codfacile"
                      autoComplete="current-password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <label
                  htmlFor="file-upload"
                  className="mt-3 py-2 bg-green-500 relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-green-facile focus-within:ring-offset-2 hover:text-white flex justify-center px-3"
                >
                  <span>
                    {selectedFile ? selectedFile?.name : "Carica CSV Ordini"}
                  </span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    accept=".csv"
                    className="sr-only"
                    onChange={(e) => LoadCsv(e)}
                  />
                </label>
              </div>{" "}
              {isError && (
                <BadgeErrorMessage
                  isError={isError}
                  message={"Errore nell'aggiornamento delle spedizioni."}
                />
              )}
              {isSuccess && (
                <BadgeSuccessMessage
                  isSuccess={isSuccess}
                  message={"Aggiornamento concluso con successo."}
                />
              )}
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  disabled={isLoading}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={(e) => handleConfirmClick(e)}
                >
                  {isLoading ? (
                    <LoadingIcon className="h-4 w-4 fill-white" />
                  ) : (
                    "Conferma"
                  )}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-facile sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShow(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PopupSelectStore;
