import {
  useGetLDVShipmentQuery,
  useLazyGetLDVShipmentQuery,
} from "../../services/shipmentApi";
import { PdfIcon } from "./PdfIcon";
import { useState } from "react";
import { LoadingIcon } from "../custom/LoadingIcon";
import { DocumentIcon, DownloadIcon } from "@heroicons/react/outline";
import { download_pdf } from "../../functions/download_pdf";
import { DEV, baseUrl } from "../../config/baseUrl";
import axios from "axios";
import { useSelector } from "react-redux";

export const DownloadPdfOrder = ({ order, Icon, style }) => {
  const [
    getLDVShipment,
    { isLoading, isSuccess, isError, error, isUninitialized },
  ] = useLazyGetLDVShipmentQuery();

  const handleDownload = () => {
    getLDVShipment({ orderId: order.id }).then((result) => {
      download_pdf(result.data, `${order.id}_${order.tracking_number}.pdf`);
    });
  };

  return (
    (order?.tracking_number ?? "") !== "" && (
      <>
        {isLoading ? (
          <LoadingIcon color={"text-gray-900"} />
        ) : isSuccess || isUninitialized ? (
          <Icon
            className={`${style} cursor-pointer`}
            onClick={() => handleDownload()}
          />
        ) : isError ? (
          <p>{error}</p>
        ) : (
          <></>
        )}
      </>
    )
  );
};

export const DownloadPdfShipment = ({ disabled, order, Icon, style }) => {
  const auth = useSelector((state) => state.auth);

  const [
    getLDVShipment,
    { isLoading, isSuccess, isError, error, isUninitialized },
  ] = useLazyGetLDVShipmentQuery();

  const handleDownload = async () => {
    try {
      var slash = DEV == "SI" ? "/" : "";
      const response = await axios.get(
        `${baseUrl}${slash}shipment/order/${order.id}/get-ldv-pdf`,
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
          responseType: "arraybuffer", // Indica che ci aspettiamo un buffer di dati binari
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching PDF", error);
    }
  };
  // const handleDownload = () => {
  //   getLDVShipment({ orderId: order.id }).then((result) => {
  //     if (result.error) {
  //       return;
  //     }
  //     download_pdf(result.data, `${order.id}_${order.tracking_number}.pdf`);
  //   });
  // };

  return (
    (order?.tracking_number ?? "") !== "" && (
      <button
        disabled={disabled}
        onClick={() => handleDownload()}
        className={`bg-gradient-to-r  ${
          disabled
            ? "from-gray-400 via-gray-500 to-gray-600 focus:ring-gray-300 dark:focus:ring-gray-800"
            : "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800 cursor-pointer"
        }   hover:bg-gradient-to-br focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-2.5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
      >
        {isLoading ? (
          <LoadingIcon color={"text-gray-900"} />
        ) : isSuccess || isUninitialized ? (
          <Icon className={`${style}`} />
        ) : isError ? (
          <p>{error}</p>
        ) : (
          <></>
        )}
      </button>
    )
  );
};
