import React, { useState } from "react";
import {
  useAddConfirmOrderPriceListMutation,
  useDeleteConfirmOrderPriceListMutation,
  useGetAllConfirmOrderPriceListQuery,
  useUpdateConfirmOrderPriceListMutation,
} from "../services/confirmOrderPriceListApi";
import { LoadingIcon } from "./custom/LoadingIcon";
import ListTable from "./custom/ListTable";
import HeaderTable from "./custom/HeaderTable";
import HeaderColTable from "./custom/HeaderColTable";
import BodyTable from "./custom/BodyTable";
import { TableLoading } from "./shipment/LoadingTable";
import RowTable from "./custom/RowTable";
import CellRowTable from "./custom/CellRowTable";
import HiddenHeaderColTable from "./custom/HiddenHeaderColTable";
import ButtonTable from "./custom/ButtonTable";
import {
  PencilAltIcon,
  PencilIcon,
  PlusIcon,
  SaveIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import PopupDialog from "./custom/PopupDialog";
import { InputField } from "./RegistrationForm";
import SimpleNotification from "./SimpleNotification";
import ModalConfirmationRequest from "./custom/ModalConfirmationRequest";

function ListConfirmOrderPriceList() {
  const [messageNotification, setMessageNotification] = useState("");
  const [successNotification, setSuccessNotification] = useState(true);
  const [showNotification, setShowNotification] = useState(false);

  const [showMessageBox, setShowMessageBox] = useState(false);

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [confirmOrderPriceList, setConfirmOrderPriceList] = useState({});
  const {
    data: confirmOrderPriceLists,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllConfirmOrderPriceListQuery();

  const [
    addConfirmOrderPriceList,
    {
      isLoading: loadingConfirmOrderPriceListAdd,
      isSuccess: successConfirmOrderPriceListAdd,
      isError: errorConfirmOrderPriceListEventAdd,
      error: errorConfirmOrderPriceListTextAdd,
    },
  ] = useAddConfirmOrderPriceListMutation();

  const [
    updateConfirmOrderPriceList,
    {
      isLoading: loadingConfirmOrderPriceListUpdate,
      isSuccess: successConfirmOrderPriceListUpdate,
      isError: errorConfirmOrderPriceListEventUpdate,
      error: errorConfirmOrderPriceListTextUpdate,
    },
  ] = useUpdateConfirmOrderPriceListMutation();

  const [
    deleteConfirmOrderPriceList,
    {
      isLoading: loadingConfirmOrderPriceListDelete,
      isSuccess: successConfirmOrderPriceListDelete,
      isError: errorConfirmOrderPriceListEventDelete,
      error: errorConfirmOrderPriceListTextDelete,
    },
  ] = useDeleteConfirmOrderPriceListMutation();

  const onSubmitEditPopup = (e) => {
    e.preventDefault();
    if (confirmOrderPriceList?.id == undefined) {
      addConfirmOrderPriceList(confirmOrderPriceList).then((res) => {
        if (res.error) {
          setSuccessNotification(false);
          setMessageNotification("Aggiunta Listino non andata a buon fine.");
          setShowNotification(true);
          return;
        }
        setSuccessNotification(true);
        setMessageNotification("Aggiunta Listino andata a buon fine.");
        setShowNotification(true);
      });
    } else {
      updateConfirmOrderPriceList(confirmOrderPriceList).then((res) => {
        if (res.error) {
          setSuccessNotification(false);
          setMessageNotification("Modifica Listino non andata a buon fine.");
          setShowNotification(true);
          return;
        }
        setSuccessNotification(true);
        setMessageNotification("Modifica Listino andata a buon fine.");
        setShowNotification(true);
      });
    }
    setOpenEditPopup(false);
  };

  const onConfirmMessageBox = () => {
    deleteConfirmOrderPriceList(confirmOrderPriceList.id).then((res) => {
      if (res.error) {
        setShowMessageBox(false);
        setSuccessNotification(false);
        setMessageNotification(res.error.data.message);
        setShowNotification(true);
        setConfirmOrderPriceList({});
        return;
      }
      setShowMessageBox(false);
      setSuccessNotification(true);
      setMessageNotification("Eliminazione Listino andata a buon fine.");
      setShowNotification(true);
      setConfirmOrderPriceList({});
    });
  };

  return (
    <main>
      <SimpleNotification
        message={messageNotification}
        setShow={setShowNotification}
        show={showNotification}
        success={successNotification}
      />
      <PopupEditConfirmOrderPriceList
        confirmOrderPriceList={confirmOrderPriceList}
        onSubmitEditPopup={onSubmitEditPopup}
        openEditPopup={openEditPopup}
        setConfirmOrderPriceList={setConfirmOrderPriceList}
        setOpenEditPopup={setOpenEditPopup}
      />
      <ModalConfirmationRequest
        showModal={showMessageBox}
        setShowModal={setShowMessageBox}
        message={"Confermi la cancellazione del listino ?"}
        handleConfirm={onConfirmMessageBox}
      />
      {isLoading ? (
        <TableLoading />
      ) : isSuccess ? (
        <>
          <ListTable>
            <HeaderTable>
              <RowTable>
                <HeaderColTable>Codice</HeaderColTable>
                <HeaderColTable>Prezzo</HeaderColTable>
                <HeaderColTable>Predefinito</HeaderColTable>
                <HiddenHeaderColTable />
              </RowTable>
            </HeaderTable>
            <BodyTable>
              {confirmOrderPriceLists?.map((confirmOrderPriceList) => (
                <RowTable key={confirmOrderPriceList.id}>
                  <CellRowTable>
                    {confirmOrderPriceList.name ?? ""}
                  </CellRowTable>
                  <CellRowTable>
                    {confirmOrderPriceList.price ?? 0}
                  </CellRowTable>
                  <CellRowTable>
                    <input
                      type={"checkbox"}
                      checked={confirmOrderPriceList.isDefault ?? false}
                      disabled={true}
                    />
                  </CellRowTable>
                  <CellRowTable>
                    <div className="flex gap-2">
                      <ButtonTable
                        Icon={PencilIcon}
                        color={
                          "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
                        }
                        onClick={() => {
                          setConfirmOrderPriceList(confirmOrderPriceList);
                          setOpenEditPopup(true);
                        }}
                      />
                      <ButtonTable
                        Icon={TrashIcon}
                        color={
                          "from-red-400 via-red-500 to-red-600 focus:ring-red-300 dark:focus:ring-red-800"
                        }
                        onClick={() => {
                          setShowMessageBox(true);
                          setConfirmOrderPriceList(confirmOrderPriceList);
                        }}
                      />
                    </div>
                  </CellRowTable>
                </RowTable>
              ))}
            </BodyTable>
          </ListTable>{" "}
          <button
            disabled={false}
            onClick={() => {
              setConfirmOrderPriceList({});
              setOpenEditPopup(true);
            }}
            type="submit"
            className="mt-2 w-full justify-center bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          >
            <PlusIcon className="h-4 w-4" />
            Aggiungi Listino Conferma Ordine
          </button>
        </>
      ) : (
        <></>
      )}
    </main>
  );
}

const PopupEditConfirmOrderPriceList = ({
  openEditPopup,
  setOpenEditPopup,
  onSubmitEditPopup,
  setConfirmOrderPriceList,
  confirmOrderPriceList,
}) => {
  return (
    <PopupDialog open={openEditPopup} setOpen={setOpenEditPopup}>
      <h1>Listino Conferma Ordine</h1>
      <form className="space-y-6" onSubmit={onSubmitEditPopup}>
        <div className="flex gap-2">
          <InputField
            required={true}
            label={"Nome"}
            type={"text"}
            placeholder={"Inserisci Nome Listino"}
            value={confirmOrderPriceList?.name ?? ""}
            onChange={(e) =>
              setConfirmOrderPriceList({
                ...confirmOrderPriceList,
                name: e.target.value,
              })
            }
          />
          <InputField
            required={true}
            label={"Prezzo"}
            type={"number"}
            placeholder={"Inserisci Prezzo Listino"}
            value={confirmOrderPriceList?.price ?? ""}
            onChange={(e) =>
              setConfirmOrderPriceList({
                ...confirmOrderPriceList,
                price: e.target.value,
              })
            }
          />
        </div>
        <div className="flex gap-2">
          <input
            type="checkbox"
            id="confirm-order-price-list-is-default"
            name="confirm-order-price-list-is-default"
            checked={confirmOrderPriceList?.isDefault ?? false}
            onChange={(e) =>
              setConfirmOrderPriceList({
                ...confirmOrderPriceList,
                isDefault: e.target.checked,
              })
            }
          />
          <label htmlFor="confirm-order-price-list-is-default">
            Predefinito?
          </label>
        </div>
        <div className="flex flex-col">
          <button
            disabled={false}
            onClick={() => console.log(confirmOrderPriceList)}
            type="submit"
            className="w-full justify-center bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          >
            {(confirmOrderPriceList?.id ?? "") == 0 ? "Salva" : "Modifica"}
            {(confirmOrderPriceList?.id ?? "") == 0 ? (
              <SaveIcon className="h-4 w-4" />
            ) : (
              <PencilAltIcon className="h-4 w-4" />
            )}
          </button>
          {(confirmOrderPriceList?.id ?? "") !== "" && (
            <button
              disabled={false}
              onClick={() => {
                setOpenEditPopup(false);
                setConfirmOrderPriceList({});
              }}
              type="submit"
              className="w-full justify-center bg-gradient-to-r bg-gray-300 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-gray-800 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
              Annulla
            </button>
          )}
        </div>
      </form>
    </PopupDialog>
  );
};

export default ListConfirmOrderPriceList;
