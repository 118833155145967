import { userApi } from "./userApi";

const termsAndConditionsApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    saveTermsAndConditions: builder.mutation({
      query: ({ termsAndConditions, ipAddress }) => ({
        url: `terms-and-conditions/save`,
        method: "POST",
        body: { termsAndConditions, ipAddress },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useSaveTermsAndConditionsMutation } = termsAndConditionsApi;
