import { DocumentDuplicateIcon } from "@heroicons/react/outline";
import { Tooltip } from "@mui/material";
import React, { useState } from "react";

function TextCopyToClipboard({ text, textHover }) {
  const [click, setClick] = useState(false);
  if (click == true) {
    setTimeout(() => {
      setClick(false);
    }, 1000);
  }

  return (
    text && (
      <Tooltip title={`${click ? "COPIATO" : textHover} `} placement="right">
        <button
          onClick={() => {
            navigator.clipboard.writeText(text);
            setClick(true);
          }}
          className="text-gray-400 cursor-pointer z-10 flex gap-1 items-center w-fit"
        >
          <span>{text}</span>
          <DocumentDuplicateIcon className="h-4 w-4" />
        </button>
      </Tooltip>
    )
  );
}

export default TextCopyToClipboard;
