import React, { useEffect, useState } from "react";
import { useGetAllStoreQuery } from "../../services/shopifyApi";
import DropDown from "./NewShopifyOrderList/DropDown";

function DropDownStore({ defaultValue, onSelectedStore }) {
  const [shopifyStores, setShopifyStores] = useState(defaultValue);

  var {
    stores,
    isLoading: isLoadingStores,
    isSuccess: isSuccessStores,
    isError: isErrorStores,
    error: errorStores,
  } = useGetAllStoreQuery(undefined, {
    skip: defaultValue == undefined,
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      var stores = data?.map((store) => ({
        id: store.id,
        name:
          (store?.aliasStore ?? "") == ""
            ? store?.storeName
            : store?.aliasStore,
      }));

      return {
        stores: stores ? [defaultValue, ...stores] : [defaultValue],
        isLoading,
        isSuccess,
        isError,
        error,
      };
    },
  });

  useEffect(() => {
    if (isSuccessStores) {
      setShopifyStores(stores[0]);
    }
  }, [isLoadingStores]);

  if (defaultValue == undefined) return <></>;

  const onChange = (store) => {
    setShopifyStores(store);
    onSelectedStore(store);
  };

  return (
    isSuccessStores && (
      <div>
        <DropDown
          items={stores}
          selectedItem={shopifyStores}
          onChange={onChange}
          label={"Negozi"}
        />
      </div>
    )
  );
}

export default DropDownStore;
