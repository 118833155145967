import {
  CheckCircleIcon,
  PencilAltIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import { LoadingIcon } from "../custom/LoadingIcon";

function InputToggleEdit({
  onEndEdit,
  defaultValue,
  setValue,
  hintTextDisable,
  hintTextEnable,
  isLoading,
  Icon,
}) {
  const [editMode, setEditMode] = useState(false);
  return (
    <div className="flex items-center justify-end gap-2 flex-1">
      {editMode ? (
        <>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              value={defaultValue}
              onChange={(e) => setValue(e.target.value)}
              type="text"
              id="sku"
              name="sku"
              placeholder={
                (defaultValue ?? "") == "" ? hintTextEnable : defaultValue
              }
              className=" block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            />
          </div>

          {isLoading ? (
            <LoadingIcon className="h-6 w-6" />
          ) : (
            <>
              <CheckCircleIcon
                onClick={() => {
                  onEndEdit(true);
                  setEditMode(false);
                }}
                className="h-6 w-6 cursor-pointer hover:shadow-md text-green-800 hover:bg-green-500 hover:text-white hover:p-0.5 rounded-md"
              />
              <XIcon
                onClick={() => {
                  onEndEdit(false);
                  setEditMode(false);
                }}
                className="h-6 w-6 cursor-pointer hover:shadow-md text-red-800 hover:bg-red-500 hover:text-white hover:p-0.5 rounded-md"
              />
            </>
          )}
        </>
      ) : (
        <>
          <span className="border rounded-md px-2">
            {(defaultValue ?? "") == "" ? hintTextDisable : defaultValue}
          </span>
          <PencilAltIcon
            onClick={() => setEditMode(true)}
            className="h-5 w-5 cursor-pointer"
          />
        </>
      )}
    </div>
  );
}

export default InputToggleEdit;
