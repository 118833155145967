export const ConfirmOrderStatusEnums = {
  Confermato: "✅ CONFERMATO",
  Annullato: "🟥 ANNULLATO",
  NonRisponde: "🟧 NON RISPONDE",
  NumeroErrato: "🟥 NUMERO ERRATO",
  DaChiamare: "📱 DA CHIAMARE",
};

export const ConfirmOrderStatusRadioButtons = [
  { id: undefined, name: "Tutti" },

  {
    id: ConfirmOrderStatusEnums.DaChiamare,
    name: ConfirmOrderStatusEnums.DaChiamare,
  },
  {
    id: ConfirmOrderStatusEnums.Confermato,
    name: ConfirmOrderStatusEnums.Confermato,
  },
  {
    id: ConfirmOrderStatusEnums.NonRisponde,
    name: ConfirmOrderStatusEnums.NonRisponde,
  },
  {
    id: ConfirmOrderStatusEnums.NumeroErrato,
    name: ConfirmOrderStatusEnums.NumeroErrato,
  },
  {
    id: ConfirmOrderStatusEnums.Annullato,
    name: ConfirmOrderStatusEnums.Annullato,
  },
];
