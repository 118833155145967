import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { usePaySubscriptionMutation } from "../../services/stripeApi";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setSubscription } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo_trasp.png";

export const CheckoutSubscriptionForm = ({ subscriptionPrice }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [successPayment, setSuccessPayment] = useState(false);
  const [errorPayment, setErrorPayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [paySubscription] = usePaySubscriptionMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      iconStyle: "solid",
      hidePostalCode: true,
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSubmitSub = async () => {
    // setIsProcessingPayment(true);
    // if (!stripe || !elements) {
    //   // Stripe.js has not yet loaded.
    //   // Make sure to disable form submission until Stripe.js has loaded.
    //   return;
    // }
    // const result = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: elements.getElement(CardNumberElement), //elements.getElement(CardElement),
    //   billing_details: {
    //     email: "fg@gmail.com",
    //   },
    // });

    //if (result.error) {
    //setErrorPayment(true);
    //setErrorMessage(result.error.message);
    //} else {
    await paySubscription({
      paymentMethod: "", //result.paymentMethod.id,
      subPrice: subscriptionPrice.subscriptionPrice,
    }).then((res) => {
      if (res.error) {
        setIsProcessingPayment(false);
        setSuccessPayment(false);
        setErrorPayment(true);
        setErrorMessage(res.error.data.message);
        return;
      }
      window.location.href = res.data;

      return;
      // const { client_secret, status, subPrice: subscriptionPrice } = res.data;

      // if (status === "requires_action") {
      //   stripe.confirmCardPayment(client_secret).then(function (result) {
      //     if (result.error) {
      //       setIsProcessingPayment(false);
      //       setSuccessPayment(false);
      //       setErrorPayment(true);
      //       setErrorMessage(result.error.message);
      //     } else {
      //       setIsProcessingPayment(false);
      //       setSuccessPayment(true);
      //       dispatch(setSubscription({ subscriptionPrice }));
      //       navigate("/integration", { replace: true });
      //       console.log("You got the money!");
      //     }
      //   });
      // } else {
      //   setErrorPayment(false);
      //   setErrorMessage(null);
      //   setSuccessPayment(true);
      //   setIsProcessingPayment(false);
      //   dispatch(setSubscription({ subscriptionPrice }));
      //   navigate("/integration", { replace: true });
      //   console.log("You got the money!");
      // }
    });
    //}
  };
  return (
    <>
      <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
        <div className="flex justify-center">
          <img className="h-10 w-auto mb-4" src={logo} alt="codfacile" />
        </div>
        <div>
          <label htmlFor="card-number" className="sr-only">
            Card number
          </label>
          <div id="express-checkout-element"></div>
          <CardNumberElement
            className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-3 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            options={{ showIcon: true }}
          />
        </div>
        <div className="flex -space-x-px">
          <div className="w-1/2 min-w-0 flex-1">
            <label htmlFor="card-expiration-date" className="sr-only">
              Expiration date
            </label>
            <CardExpiryElement className="relative block w-full rounded-none rounded-bl-md border-0 bg-transparent py-3 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
          </div>
          <div className="min-w-0 flex-1">
            <label htmlFor="card-cvc" className="sr-only">
              CVC
            </label>
            <CardCvcElement className="relative block w-full rounded-none rounded-br-md border-0 bg-transparent py-3 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
          </div>
        </div>
      </div>
      <button
        className="mt-2 w-full rounded-md border border-transparent bg-green-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50"
        onClick={handleSubmitSub}
      >
        {isProcessingPayment && successPayment == false
          ? "Pagamento in corso..."
          : isProcessingPayment == false && successPayment == true
          ? "Pagamento eseguito con successo"
          : `Abbonati a ${subscriptionPrice.price}`}
      </button>{" "}
      <div className="text-center">
        {errorMessage && !isProcessingPayment && (
          <span
            className="mt-2 inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700"
            id="payment-message"
          >
            <svg
              className="h-1.5 w-1.5 fill-red-500"
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            {errorMessage}
          </span>
        )}
      </div>
    </>
  );
};
