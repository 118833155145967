import { userApi } from "./userApi";

const productCatalogInCartApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    addOneProductCatalogInCart: builder.mutation({
      query: (productCatalogInCart) => {
        return {
          url: `product-catalog-in-cart/add-one`,
          method: "POST",
          body: { productCatalogInCart },
        };
      },
      invalidatesTags: ["ProductCatalogInCart"],
    }),
    editOneProductCatalogInCart: builder.mutation({
      query: (productCatalogInCart) => {
        return {
          url: `product-catalog-in-cart/edit-one`,
          method: "PUT",
          body: { productCatalogInCart },
        };
      },
      invalidatesTags: ["ProductCatalogInCart"],
    }),
    deleteOneProductCatalogInCart: builder.mutation({
      query: (productCatalogInCart) => {
        return {
          url: `product-catalog-in-cart/delete-one`,
          method: "DELETE",
          body: { productCatalogInCart },
        };
      },
      invalidatesTags: ["ProductCatalogInCart"],
    }),
    getAllProductCatalogInCart: builder.query({
      query: () => `product-catalog-in-cart`,
      providesTags: ["ProductCatalogInCart"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllProductCatalogInCartQuery,
  useAddOneProductCatalogInCartMutation,
  useDeleteOneProductCatalogInCartMutation,
  useEditOneProductCatalogInCartMutation,
} = productCatalogInCartApi;
