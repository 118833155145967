import { useEffect, useState } from "react";

export default function TextArea({
  onChange,
  placeholder,
  defaultValue,
  label,
}) {
  const [text, setText] = useState(defaultValue ?? "");

  useEffect(() => {
    if (onChange !== undefined) onChange(text);
  }, [text]);
  return (
    <div>
      {label && (
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      )}
      <div className="mt-2">
        <textarea
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          rows={4}
          name="comment"
          id="comment"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
