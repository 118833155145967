import React from "react";

function BannerGeneratingShipment({ partial, total }) {
  return (
    <div className="relative mt-3">
      <div className="context">
        <h1>
          Spedizioni generate: {partial}/{total}
        </h1>
      </div>

      <div className="area">
        <ul className="circles">
          <li>
            <img
              src="https://codfacile.com/cdn/shop/files/logo_trasp.png?v=1697882932&width=220"
              width="100px"
            />
          </li>

          <li>
            <img
              src="https://static.thenounproject.com/png/11030-200.png"
              width="100px"
            />
          </li>
          <li>
            <img
              src="https://codfacile.com/cdn/shop/files/logo_trasp.png?v=1697882932&width=220"
              width="100px"
            />
          </li>
          <li>
            <img
              src="https://static.thenounproject.com/png/11030-200.png"
              width="100px"
            />
          </li>
          <li>
            <img
              src="https://pnghq.com/wp-content/uploads/delivery-truck-pngs-for-free-download-png-smooth-edges.png"
              width="75px"
            />
          </li>
          <li>
            <img
              src="https://codfacile.com/cdn/shop/files/logo_trasp.png?v=1697882932&width=220"
              width="115px"
            />
          </li>
          <li>
            <img
              src="https://static.thenounproject.com/png/11030-200.png"
              width="150px"
            />
          </li>
          <li>
            <img
              src="https://codfacile.com/cdn/shop/files/logo_trasp.png?v=1697882932&width=220"
              width="75px"
            />
          </li>
          <li>
            <img
              src="https://pnghq.com/wp-content/uploads/delivery-truck-pngs-for-free-download-png-smooth-edges.png"
              width="90px"
            />
          </li>
          <li>
            <img
              src="https://static.thenounproject.com/png/11030-200.png"
              width="175px"
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BannerGeneratingShipment;
