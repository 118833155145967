import React, { useEffect, useState } from "react";

function Input({
  onChange,
  placeholder,
  defaultValue,
  label,
  min,
  step,
  type,
}) {
  const [text, setText] = useState(defaultValue ?? "");

  useEffect(() => {
    if (onChange !== undefined) onChange(text);
  }, [text]);

  return (
    <div className="mt-2">
      {label && (
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      )}
      <input
        type={type}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        min={min}
        step={step}
        id="search-customer"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 pl-2 "
        placeholder={placeholder}
      />
    </div>
  );
}

export default Input;
