/* This example requires Tailwind CSS v2.0+ */
import { XIcon } from "@heroicons/react/outline";
import { CheckIcon, SwitchHorizontalIcon } from "@heroicons/react/solid";
import { ErrorOutline } from "@mui/icons-material";
import { useState } from "react";
import { useGetTrackingDetailsQuery } from "../../services/shipmentApi";
import { LoadingIcon } from "../custom/LoadingIcon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ListTrackingMovement({
  trackingDetails,
  isSuccess,
  isLoading,
  isError,
  error,
}) {
  return (
    <div className="flow-root">
      {isLoading ? (
        <LoadingIcon color={"text-gray-900"} />
      ) : isSuccess ? (
        trackingDetails == undefined ? (
          <></>
        ) : (
          <ul role="list" className="-mb-8">
            {trackingDetails.map((detail, eventIdx) => (
              <li key={detail.Data}>
                <div className="relative pb-8">
                  {eventIdx !== trackingDetails.length - 1 ? (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      {detail.Stato == "Spedizione non trovata" ? (
                        <span
                          className={classNames(
                            "bg-red-500",
                            "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                          )}
                        >
                          <XIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      ) : eventIdx !== 0 || detail.Stato == "CONSEGNATA" ? (
                        <span
                          className={classNames(
                            "bg-green-500",
                            "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                          )}
                        >
                          <CheckIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        <span
                          className={classNames(
                            "bg-yellow-500",
                            "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                          )}
                        >
                          <SwitchHorizontalIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          {detail.content}{" "}
                          <span className="font-medium text-gray-900">
                            {detail.Stato}
                          </span>
                        </p>
                      </div>
                      <div className="text-right text-sm whitespace-nowrap text-gray-500">
                        <time dateTime={detail.Data}>{detail.Data}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )
      ) : isError ? (
        <p>{error}</p>
      ) : (
        <></>
      )}
    </div>
  );
}
