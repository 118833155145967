import React from "react";

function TermsAndConditionsText() {
  return (
    <>
      {" "}
      <div className="mb-5">
        <h1 className="text-4xl font-bold">Termini e Condizioni di Servizio</h1>{" "}
      </div>
      <p>
        I presenti Termini e Condizioni disciplinano la vendita dei Servizi
        sulla piattaforma https://codfacile.com/ e i vari sottodomini. &nbsp;Il
        Fornitore si riserva il diritto di modificare i presenti Termini e
        Condizioni di vendita e la Privacy Policy e le predette modifiche
        saranno rese note agli Utenti tramite il Sito con un apposito avviso.
        Detta comunicazione potrà, a discrezione del Fornitore essere effettuata
        anche attraverso l’invio di un’apposita email. I Servizi offerti
        mediante il sito web sono rivolti esclusivamente a Professionisti e in
        generale a chi vende online con un e-commerce o un marketplace.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 1 DEFINIZIONI</strong>
      </p>
      <p>Ai fini del presente contratto, si intende per:</p>
      <p>
        <strong>Fornitore del servizio</strong>: GVC Group SRL, con sede in Via
        G. Pergolesi 9, Sant’Antimo NA- P.IVA 10468391213 – PEC:
      </p>
      <p>gvcgroupsrl@pec.it e-mail: info@codfacile.com</p>
      <p>
        <strong>Termini e Condizioni generali: </strong>l’insieme delle presenti
        clausole contrattuali che determinano e definiscono i rapporti tra il
        Fornitore e Cliente
      </p>
      <p>
        <strong>Sito web/Piattaforma</strong>: https://codfacile.com/
      </p>
      <p>
        <strong>Servizi:</strong> vendita in abbonamento di Servizio e Prodotti
      </p>
      <p>
        <strong>Cliente: </strong>qualsiasi soggetto che acquista il Servizio
        venduto tramite il Sito
      </p>
      <p>
        <strong>Professionista</strong>: colui che agisce per scopi
        professionali o imprenditoriali
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 2 OGGETTO DEL CONTRATTO</strong>
      </p>
      <p>
        I presenti Termini e Condizioni di vendita riguardano i Servizi di cui
        al successivo articolo e sono validi tra il Fornitore e qualsiasi
        Cliente che effettua un acquisto sul Sito, stabiliscono le condizioni di
        utilizzo del sito web, le modalità di utilizzo dei Servizi. Eventuali
        tolleranze del Fornitore ai comportamenti che siano in violazione di
        quanto previsto dalle presenti condizioni non costituiscono rinuncia ai
        diritti che spettano a tale parte in base alle predette. Qualora una
        delle condizioni risulti essere nulla o inefficace, l’eventuale nullità
        o inefficacia non si estenderà alle restanti clausole contrattuali.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 3 DESCRIZIONE DEL SERVIZIO</strong>
      </p>
      <p>
        Il Fornitore offre ai propri Clienti l’accesso alla Piattaforma
        https://codfacile.com/ che permette la gestione dei processi di
        comunicazione delle merci in arrivo presso il magazzino del Fornitore,
        l’acquisizione online di ordini e di lead provenienti dal proprio sito,
        la possibilità di gestire i propri ordini e lead al fine di effettuare
        spedizioni attraverso primari corrieri sul territorio italiano. La
        Piattaforma inoltre permette il monitoraggio continuo dello stato e dei
        costi dell’ordine fino alla consegna finale. Il Fornitore per l’intera
        durata del presente Contratto, fornirà al Cliente un’autorizzazione
        all’uso della Piattaforma e i relativi accessi. Il Fornitore garantirà
        ogni presidio di sicurezza alle transazioni, nonché, alla tutela e
        integrità dei dati trattati. La piattaforma è dedicata ai Professionisti
        che vendono online sia tramite Shopify che attraverso altri player e
        vari Marketplace. Il Fornitore mette a disposizione del Cliente
        l’acquisto di prodotti dal catalogo presente in Piattaforma. Il client
        prende atto, accetta e manleva da ogni responsabilità il Fornitore nel
        caso in cui i Prodotti acquistati risultino esauriti o siano differenti
        per colore e altre qualità da quelli illustrati nella Piattaforma. Il
        servizio offerto dal Fornitore è attualmente integrato con Shopify,
        mentre per gli altri -commerce o marketplace i Clienti dovranno caricare
        nella Piattaforma un file CSV con gli ordini.&nbsp;&nbsp;
      </p>
      <p>
        La Piattaforma dopo aver ricevuto gli ordini o in automatico, se
        implementata con Shopify o con caricamento di fil CSV, provvede a
        dividere le attività in dropshipping o Contrassegno.
      </p>
      <p>
        <strong>
          <em>1) Modalità Dropshipping</em>
        </strong>
      </p>
      <p>
        Nel caso in cui la modalità di vendita dei beni sia effettuata in
        dropshipping il Cliente prende atto e accetta che prima dell’evasione
        dell’ordine lo stesso andrà pagato al Fornitore. L’ordine potrà
        eventualmente essere annullato fino a ché lo stesso non riporti la
        dicitura “<em>in lavorazione</em>”.
      </p>
      <p>
        <strong>
          2) <em>Modalità contrassegno</em>
        </strong>
      </p>
      <p>
        Nel caso in cui la modalità di vendita dei beni sia effettuata in
        contrassegno, il Fornitore offre il Servizio “
        <em>conferma dell’ordine</em>” valido per singolo ordine. Attraverso
        questo servizio l’acquirente finale viene contattato dal Fornitore per
        avere conferma dell’ordine. In caso di conferma dell’ordine la
        piattaforma automaticamente evaderà la richiesta. In caso di non
        acquisto del servizio “<em>conferma dell’ordine</em>” per ciascun ordine
        il Cliente dovrà manualmente gestire l’evasione dello stesso.&nbsp; In
        caso di servizio di contrassegno il Fornitore anticipa il costo della
        merce e le spese di spedizione. Il Cliente prende atto e accetta che in
        caso di giacenza l’importo dovrà essere comunque pagato al Fornitore. Al
        contrassegno va applicata la rimessa. La rimessa consiste nella
        differenza tra l’importo indicato in fattura per i prodotti /sevizi del
        Fornitore e il prezzo incassato dallo spedizioniere al momento della
        consegna. Le rimesse vengono pagate settimanalmente salvo eventuali
        ritardi dovuti alla consegna o altri casi non prevedibili. Il Client
        prende atto e accetta che in caso di saldo negativo degli ordini per
        giorni 30 consecutivi il Fornitore provvederà a richiedere per iscritto
        il Cliente di provvedere al pagamento e in caso negativo a estromettere
        lo stesso dalla Piattaforma e dai servizi offerti.
      </p>
      <p>
        <strong>Servizio di Deposito dei prodotti</strong>
      </p>
      <p>
        Il Fornitore offre servizio di deposito dei prodotti presso il proprio
        magazzino ubicato in Via G. Pergolesi 9 Sant’Antimo 80029 (NA). Il
        Fornitore fornirà servizi di deposito e custodia dei prodotti inviati
        dal Cliente e in nessun caso modificherà il contenuto delle confezioni
        dei beni ricevuti in custodia che dovranno essere sigillati in modo da
        rendere evidente l’assoluta assenza di attività del Fornitore sul
        contenuto degli stessi. Il Cliente prende atto e accetta che, il
        Fornitore non si assume nessuna responsabilità riguardo all’integrità
        dei beni ricevuti e/o per danneggiamenti ai prodotti sino all’ingresso
        fisico nel magazzino. Il Cliente si obbliga a comunicare anticipatamente
        la tipologia e la quantità di merce che invierà presso il magazzino di
        fermo restando che in ogni caso le merci dovranno essere conformi alla
        normativa applicabile vigente e contenuti in confezioni sigillate. Il
        Fornitore si limiterà ad aprire i colli ricevuti al fine di prendere in
        custodia i prodotti, senza aprire le confezioni dei singoli prodotti
        ricevuti in custodia. In caso di urgenti contingenze che rendano
        necessario o opportuno modificare le modalità di custodia e
        conservazione dei prodotti, il Fornitore potrà derogare a quanto
        previsto dal presente Contratto previa comunicazione al Cliente. Nel
        caso in cui il Fornitore ricevesse un collo di prodotti il cui
        imballaggio è integro ma che presenta internamente delle confezioni
        danneggiate, non sarà responsabile se comunica tale difetto al Cliente
        entro 7 giorni dalla ricezione della merce. La movimentazione delle
        merci all’interno del deposito è di competenza esclusiva del Fornitore
        e/o di suoi subfornitori. Il Fornitore è libero di adottare le soluzioni
        ritenute più opportune per lo stoccaggio, la movimentazione e la
        gestione delle merci nel pieno rispetto degli impegni assunti con il
        Cliente. Tutte le operazioni verranno eseguite all’interno di magazzini
        coperti. Il Cliente dovrà fornire tutte le informazioni utili per una
        corretta conservazione e custodia dei prodotti consegnati, dichiarando
        in ogni caso che non saranno consegnati prodotti pericolosi, preziosi,
        radioattivi, diretti all’attività terroristica, né rifiuti di ogni
        genere.&nbsp; Resta esclusa la responsabilità per caso fortuito o forza
        maggiore o allorché il pregiudizio derivi da informazioni inesatte o
        reticenti del Cliente o dalle caratteristiche proprie del bene affidato
        o nel caso in cui i prodotti non siano stati acquistati dal Fornitor ma
        da terzi.
      </p>
      <p>
        <strong>Servizi di logistica per la consegna</strong>
      </p>
      <p>
        Il Fornitore fornirà servizi di logistica finalizzati alla consegna agli
        acquirenti finali dei beni gestiti sulla Piattaforma. Il Fornitore
        provvederà all’allestimento degli ordini dei prodotti depositati
        trasmessi dal Cliente in modo da soddisfare le diverse esigenze di
        fornitura. Il Fornitore fornirà tale servizio mediante l’utilizzo di
        corrieri o altri vettori abilitati. Il servizio prevede la consegna al
        luogo di destinazione alle condizioni generali di trasporto del corriere
        che il Cliente dichiara di conoscere e accettare.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 4 DURATA DEL SERVIZIO</strong>
      </p>
      <p>
        Il Cliente registrato accede al catalogo e può utilizzare il servizio
        fino ad un numero di ordini prestabilito dal Fornitore indicato nella
        piattaforma. Al superamento di tale numero il Cliente dovrà
        sottoscrivere un abbonamento tra i pacchetti proposti dal Fornitore.
        Dopo aver effettuato l’acquisto il rapporto si intende rinnovato
        tacitamente ogni mese salvo annullamento del rinnovo automatico e
        conseguente disdetta da effettuare in qualsiasi momento antecedente la
        data del rinnovo. La disdetta diverrà efficace alla fine del periodo di
        fatturazione in corso.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 5 PREZZI</strong>
      </p>
      <p>
        I prezzi sono esposti nel Sito web in euro. Il Fornitore si riserva il
        diritto di modificare il prezzo, in ogni momento, senza preavviso, fermo
        restando che il prezzo addebitato al Cliente sarà quello indicato sul
        sito al momento dell’effettuazione dell’ordine e che non si terrà conto
        di eventuali variazioni (in aumento o in diminuzione) successive alla
        trasmissione dello stesso.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART.&nbsp; 6 MODALITÀ DI REGISTRAZIONE</strong>
      </p>
      <p>
        Al fine di utilizzare e acquistare i Servizi e/o Prodotti del Fornitore,
        il Cliente dovrà effettuare un’apposita registrazione, attraverso cui
        inserire i propri dati personali richiesti. L’Utente è responsabile
        della veridicità e correttezza degli stessi. È fatto divieto di fornire
        false generalità e di compiere qualsiasi condotta idonea a ingenerare
        confusione in ordine alla identità personale dell’Utente. A titolo
        esemplificativo, tale divieto comprende l’uso di generalità non vera o
        altrui.
      </p>
      <p>
        Al momento della registrazione e al momento dell’inserimento dei dati
        l’Utente garantisce di:
      </p>
      <p>– essere maggiorenne e capace giuridicamente;</p>
      <p>
        – osservare tutte le norme di legge e contrattuali applicabili ai
        presenti Termini e Condizioni;
      </p>
      <p>
        – essere il legittimo titolare dei dati inseriti, da intendersi veri,
        corretti ed aggiornati.
      </p>
      <p>
        All’atto della registrazione verrà richiesto all’Utente di conferire
        alcuni dati quali il nome e la e-mail. L’indirizzo e-mail conferito in
        sede di registrazione permette al Fornitore di notificare all’Utente
        successivamente all’acquisto l’invito a creare un account con
        l’inserimento di una password che l’Utente avrà cura di custodire,
        conservare, tenere segreta, usare in proprio e non cedere. L’indirizzo
        e-mail conferito in sede di registrazione permette altresì al Fornitore
        di notificare all’Utente tutti i messaggi relativi ai Servizi e al Sito
        in generale. È fatto divieto di utilizzare mail temporanee per la
        registrazione.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART.&nbsp; 7 MODALITÀ DI UTILIZZO DEI SERVIZI</strong>
      </p>
      <p>
        I Servizi si intendono erogati esclusivamente a seguito del rilascio del
        permesso di accesso e fornitura delle credenziali di accesso recapitate
        tramite posta elettronica all’indirizzo e-mail indicato al momento della
        registrazione. Il Cliente al primo utilizzo dovrà necessariamente
        modificare la Password ricevuta per assolvere completamente alla
        protezione dell’area dati allo stesso riservata. A partire dal primo
        accesso, il Cliente si assume ogni onere e responsabilità in ordine
        all’utilizzazione del servizio e dei dati immessi sugli archivi. Il
        Cliente è obbligato a conservare in modo riservato la Password e la User
        ID, che rimangono strettamente
      </p>
      <p>
        personali, non cedibili e utilizzate solo per accedere ai servizi
        acquistati (procedura di autenticazione). Il Cliente garantisce di porre
        in essere ogni misura di sicurezza necessaria ad impedire ogni indebito
        accesso al servizio da parte di terzi non autorizzati allo stesso,
        rimanendo unico ed esclusivo responsabile di qualsiasi accadimento,
        furto, smarrimento della USER ID e PASSWORD tenendo sollevato e indenne
        il Fornitore da qualsiasi fatto, ivi comprese la manomissione,
        distruzione, cancellazione, mancata registrazione o elaborazione totale
        e/o parziale di dati e/o programmi, conseguente a detta perdita avvenuta
        dopo la trasmissione al Cliente dei codici stessi. Il Cliente potrà
        autorizzare l’accesso ai propri archivi e l’uso dell’applicativo in
        tutto o parte del servizio a propri collaboratori assumendosi la
        responsabilità diretta dei permessi di accesso consentiti e le relative
        funzioni concesse. Conseguentemente, il Cliente si assume ogni onere e
        responsabilità in ordine all’utilizzazione dei propri dati. Il Fornitore
        potrà in ogni momento effettuare verifiche e controlli in ordine al
        corretto uso dei programmi. Il Fornitore non assume comunque alcuna
        responsabilità in merito alle informazioni contenute negli archivi e
        nelle banche dati. Quanto detto vale anche con riferimento ai singoli
        dati, per i quali non assume altresì alcun obbligo in ordine alla loro
        generazione, al loro mantenimento, alla loro funzione ed alla loro
        veridicità.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 8 MODALITÀ DI PAGAMENTO</strong>
      </p>
      <p>
        Il Cliente corrispondere il prezzo richiesto, secondo le modalità
        previste nel Sito. Il Partecipante si impegna a corrispondere la quota
        mensile di abbonamento del servizio autorizzando il Fornitore ad
        addebitare la stessa sul metodo di pagamento indicato. Nel caso in cui
        il pagamento venga rifiutato il Cliente sarà responsabile degli importi
        insoluti e il Fornitore potrà sospendere il suo accesso al servizio fino
        a quando non avrà attivato un Metodo di pagamento valido.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 9 OBBLIGHI RELATIVI ALL’UTILIZZO DELLA PIATTAFORMA</strong>
      </p>
      <p>
        Tutti i Clienti dovranno utilizzare la Piattaforma attenendosi
        scrupolosamente ai presenti Termini e condizioni. Il Cliente si obbliga
        a non utilizzare la Piattaforma ed i relativi servizi per scopi illegali
        o contrari ai presenti termini e condizioni di utilizzo, o con modalità
        che potrebbero danneggiarne la funzionalità, rendendolo inagibile,
        provocare sovraccarico, deterioramenti e/o interferenze con l’uso dello
        stesso da parte degli altri Clienti. È vietato ogni comportamento da
        cui, anche per meri tentativi, possano derivare accessi non autorizzati
        al sito, al Servizio venduto dal Fornitore, ad altri account, a sistemi
        o reti connessi al medesimo tramite operazioni di pirateria informatica,
        contraffazione della password o altri mezzi.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 10 PROPRIETÀ INTELLETTUALE E INDUSTRIALE</strong>
      </p>
      <p>
        Tutti i contenuti del Sito sono protetti e tutelati dalle vigenti norme
        in materia di diritto d’autore e di proprietà industriale e
        intellettuale. A titolo esemplificativo e non esaustivo per contenuto
        del Sito debbono intendersi: il nome a dominio, i relativi sotto domini,
        i marchi, tutti i testi, qualunque rappresentazione grafica e/o di testo
        in genere, fotografie, filmati. Tutti i diritti di proprietà
        intellettuale e industriale a essi relativi sono di proprietà esclusiva
        del Fornitore, sono a esso riservati e non sono né saranno trasferiti o
        concessi in licenza in alcun caso al Cliente. Pertanto, il Cliente non
        potrà riprodurre, duplicare, copiare e ridistribuire, ritrasmettere
        anche su altri siti web, trasferire o altrimenti rendere disponibili a
        terzi a qualsivoglia titolo o comunque utilizzare per fini diversi dalla
        conservazione e/o consultazione i Contenuti del Sito. Tutto il materiale
        predisposto dal Fornitore resterà di esclusiva proprietà dello stesso.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 11 RECESSO E GARANZIA LEGALE</strong>
      </p>
      <p>
        Il Fornitore garantisce che tutti i Prodotti offerti nel catalogo sono
        nuovi e privi di difetti. I Prodotti sono coperti dalla garanzia legale
        di conformità ai sensi degli artt. 128 e ss. del Codice del Consumo, che
        tutela il consumatore finale in caso di difetti di conformità che si
        manifestino entro 24 mesi dalla data di consegna del bene. La
        responsabilità per la gestione delle pratiche di garanzia legale è
        affidata al Cliente che si impegna a informare chiaramente gli
        acquirenti finali, tramite il proprio store online o mediante
        documentazione fornita con il prodotto, sulla garanzia legale.
        L’acquirente finale ha il diritto di recedere dal contratto di vendita
        con il Cliente conformemente alle normative vigenti sul diritto di
        recesso dei consumatori. In tal caso il Cliente rientrerà nel pieno
        possesso del bene.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 12 ESCLUSIONE DI RESPONSABILITÀ</strong>
      </p>
      <p>Le Parti, nei limiti di legge, convengono quanto segue:</p>
      <p>
        Il Fornitore, previa eventuale comunicazione da inviarsi al Cliente
        mediante posta elettronica, potrà interrompere il collegamento in
        presenza di comprovati problemi di sicurezza e/o di garanzia di
        riservatezza oltre il limite delle 24 ore lavorative.
      </p>
      <p>
        Il Fornitore non potrà in ogni caso essere ritenuto responsabile per
        interruzioni di servizio non dipendenti dalla propria volontà quali, a
        mero titolo esemplificativo, il malfunzionamento delle reti telefoniche,
        di quelle elettriche, dei provider, dei gestori della rete internet;
      </p>
      <p>
        In nessun caso il Fornitore sarà ritenuto responsabile di ritardi e/o
        violazioni dovute a cause fuori dal proprio controllo e/o discendenti da
        obblighi derivanti dalla legge, regolamenti, ordini, disposizioni
        amministrative promanate da qualsiasi autorità;
      </p>
      <p>
        – la disponibilità dei servizi usufruibili via web si intende fatte
        salve le sospensioni operate per motivi tecnici e, a insindacabile
        giudizio dl Fornitore, utili per garantire il miglior proseguimento dei
        servizi, come ad es.:
      </p>
      <p>• Attività di manutenzione ordinaria dei sistemi hardware;</p>
      <p>
        • Implementazione nei sistemi di nuove versioni di software di base;
      </p>
      <p>
        • Operazioni finalizzate a manutenzione ordinaria e straordinaria,
        correzione di eventuali anomalie o
      </p>
      <p>caricamento di aggiornamenti;</p>
      <p>• Operazioni di salvataggio di archivi;</p>
      <p>
        • Operazioni di ripristino di archivi, anche eventualmente su richiesta
        del cliente;
      </p>
      <p>
        • Situazioni che potrebbero far presupporre o determinare una riduzione
        o comunque un’insufficienza del livello di sicurezza degli accessi ai
        dati;
      </p>
      <p>
        • Situazioni di urgenza per le quali si debba ricorrere a interventi
        straordinari sulle componenti hardware, software e di rete, qualora
        questi siano necessari per consentire il normale utilizzo o il
        ripristino dei servizi;
      </p>
      <p>
        • Eventi di forza maggiore o comunque al di fuori della ragionevole
        capacità di previsione del Fornitore.
      </p>
      <p>
        Il Fornitore non è responsabile per l’incremento o il mancato incremento
        di affari da parte dell’attività dl Cliente, per mancati raggiungimenti
        dei risultati desiderati a fronte di investimenti e costi sostenuti e
        per qualsiasi danno derivante dai Servizi offerti.
      </p>
      <p>
        <a>Il Fornitore </a>non sarà ritenuto responsabile per omissioni o
        errori che possano essere contenuti nei materiali, né, ancora,
        dell’eventuale violazione di diritti altrui e dei danni, anche
        indiretti, consequenziali ad essa, o per altri danni di qualsiasi tipo,
        anche risultanti da perdita del diritto d’uso, perdita di informazioni o
        mancato guadagno ovvero discendenti dall’inadempimento del contratto, da
        negligenza o da altre azioni lesive, derivanti da o in qualsiasi modo
        connessi all’utilizzo o alle informazioni contenute nella piattaforma.
      </p>
      <p>
        Il Fornitore non sarà responsabile di danni o perdita dei prodotti
        oggetto di deposito, salvo in caso di dolo o colpa grave.
      </p>
      <p>
        Il Fornitore non sarà in alcun modo responsabile dell’effettivo
        contenuto delle confezioni inviate dal Cliente né assume alcuna
        responsabilità, a qualsiasi titolo, nei confronti degli acquirenti
        finali del Cliente.
      </p>
      <p>
        Il Fornitore non sarà in alcun modo responsabile per ritardi o mancati
        pagamenti dei contrassegni causati da terzi fornitori, salvo in caso di
        dolo o colpa grave.
      </p>
      <p>
        Il Fornitore non assumerà alcuna responsabilità nei confronti del
        Cliente, anche per danneggiamenti ai prodotti, sino all’ingresso fisico
        delle merci nel magazzino e dopo la consegna al trasportatore.
      </p>
      <p>
        Il Fornitore non assume alcuna responsabilità per l’operato dei
        corrieri/vettori, né per l’effettiva consegna dei prodotti al cliente
        finale.
      </p>
      <p>
        Il Cliente utilizza i servizi a proprio rischio, esonerando il Fornitore
        nei confronti di ogni parte per controversie legali/civili o
        amministrative, danni indiretti, specifici, incidentali, punitivi,
        cauzionali o consequenziali causati dall’utilizzo o dall’impossibilità
        di utilizzare i servizi oggetto del presente contratto.
      </p>
      <p>
        Le Parti si danno reciprocamente atto che, ove una o più clausole
        contenute nel presente contratto dovessero essere ritenute nulle,
        annullabili o comunque inefficaci, tale nullità o inefficacia non
        determinerà l’invalidità integrale dell’accordo medesimo e non si
        estenderà alle rimanenti clausole. In tal caso, le disposizioni
        contenute nelle clausole che dovessero essere ritenute nulle o comunque
        inefficaci saranno considerate quale impegno assunto e rilevante ai fini
        della buona fede contrattuale delle Parti.
      </p>
      <p className="mt-8 mb-2">
        <strong>Art. 13 CLAUSOLA RISOLUTIVA ESPRESSA</strong>
      </p>
      <p>
        Il Fornitore può in ogni momento e con effetto immediato risolvere il
        contratto ex art. 1456 C.C., interrompendo i servizi senza preavviso
        mediante invio al Cliente di posta elettronica PEC o Raccomandata A/R,
        nella quale dichiari di volersi avvalere della facoltà di risolvere il
        contratto, qualora si verifichino le seguenti condizioni:
      </p>
      <p>
        a) il Cliente non adempia all’obbligo di corrispondere i corrispettivi
        secondo gli importi e le scadenze di cui al presente contratto;
      </p>
      <p>b) il Cliente violi gli obblighi previsti nel presente contratto.</p>
      <p className="mt-8 mb-2">
        <strong>ART. 14 MANCATO ESERCIZIO DI UN DIRITTO</strong>
      </p>
      <p>
        Il mancato esercizio di un diritto da parte del Fornitore non
        rappresenta alcuna rinuncia ad agire nei confronti del Cliente o nei
        confronti di terzi per la violazione di impegni assunti. Il Fornitore si
        riserva, pertanto, di far valere i suoi diritti in ogni caso, nei
        termini concessi.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 15 TRATTAMENTO DEI DATI PERSONALI</strong>
      </p>
      <p>
        I Dati Personali forniti o acquisiti saranno oggetto di Trattamento
        improntato ai principi di correttezza, liceità, trasparenza e di tutela
        della riservatezza ai sensi delle vigenti normative. Il Fornitore, in
        qualità di Titolare del Trattamento, tratta i Dati Personali degli
        Utenti adottando le opportune misure di sicurezza volte ad impedire
        l’accesso, la divulgazione, la modifica o la distruzione non autorizzate
        dei Dati Personali. Il Trattamento viene effettuato mediante strumenti
        informatici e/o telematici, con modalità organizzative e con logiche
        strettamente correlate alle finalità indicate. I Dati dell’Utente sono
        raccolti per l’esecuzione di misure precontrattuali; per adempiere agli
        obblighi derivanti dal contratto stipulato; per la procedura di
        registrazione finalizzata all’acquisto dei Servizi; per dar seguito alle
        specifiche richieste rivolte al Titolare dall’Utente; per l’invio di
        informazioni ed offerte promozionali e commerciali anche tramite il
        servizio di newsletter in base al consenso liberamente espresso
        dall’Utente; per finalità di soft spam per comunicazioni promozionali
        aventi come oggetto i Servizi acquistati senza necessità del consenso
        espresso e preventivo dell’Utente, come previsto dall’art. 130, 4 comma,
        Codice della Privacy così come novellato dal D.lgs. n.101 del 2018. Si
        invita pertanto il Cliente a leggere l’informativa sul trattamento dei
        dati personali{" "}
        <strong>
          (
          <u>
            <a href="https://codfacile.com/privacy-policy/">Privacy Policy</a>
          </u>
          ){" "}
        </strong>
        resa ai sensi del Regolamento UE 679/2016, nonché sull’utilizzo dei
        cookie con il relativo consenso al trattamento laddove richiesto{" "}
        <strong>
          <u>
            <a href="https://codfacile.com/cookie-policy/">(Cookie Policy).</a>
          </u>
        </strong>
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 16 COMUNICAZIONI</strong>
      </p>
      <p>
        Tutte le comunicazioni inerenti al presente contratto per essere
        efficaci tra le Parti, salvo quanto diversamente specificato nel
        presente contratto, devono avvenire per iscritto e faranno prova tra le
        parti solamente se effettuate a mezzo posta elettronica o con altro
        mezzo che sia stato preventivamente concordato per iscritto tra le
        Parti.
      </p>
      <p className="mt-8 mb-2">
        <strong>ART. 17 LEGGE APPLICABILE E FORO COMPETENTE</strong>
      </p>
      <p>
        I presenti Termini e Condizioni sono interamente disciplinati dalla
        legge italiana. Per qualsiasi controversia che dovesse sorgere in
        relazione alla validità, interpretazione, esecuzione e risoluzione dei
        presenti Termini e Condizioni sarà di esclusiva competenza il Foro di
        Napoli.
      </p>
      <p className="mt-8 mb-2">
        Ai sensi degli art. 1341 e 1342 c.c., l’Utente dichiara di aver letto
        attentamente e di accettare espressamente tutte le clausole dei presenti
        Termini e Condizioni di vendita e in particolare gli articoli 3,12,13 e
        17.
      </p>
    </>
  );
}

export default TermsAndConditionsText;
