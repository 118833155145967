export const PromotionDuration = {
  Once: "once",
  Forever: "forever",
  Repeating: "repeating",
};

export const PromotionDurationList = [
  PromotionDuration.Once,
  PromotionDuration.Forever,
  PromotionDuration.Repeating,
];

export const PromotionDurationDropDown = [
  { id: 0, name: "Seleziona.." },
  { id: 1, name: PromotionDuration.Once },
  { id: 2, name: PromotionDuration.Forever },
  { id: 3, name: PromotionDuration.Repeating },
];
