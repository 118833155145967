import { userApi } from "./userApi";

const authApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    checkRefreshToken: builder.query({
      query: () => `refresh-token/check`,
    }),

    signIn: builder.mutation({
      query: ({ ...user }) => ({
        url: `auth/login`,
        method: "POST",
        body: user,
      }),
      //invalidatesTags: ["Auth"],
    }),
    signUp: builder.mutation({
      query: ({ ...user }) => ({
        url: `auth/register`,
        method: "POST",
        body: user,
      }),
    }),
    logOut: builder.mutation({
      query: () => ({
        url: `auth/logout`,
        method: "POST",
      }),
      invalidatesTags: [
        "Key",
        "Auth",
        "ShopifyOrders",
        "Shipments",
        "Remittance",
        "Subscription",
        "SubscriptionPayment",
        "CashFlow",
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignInMutation,
  useCheckRefreshTokenQuery,
  useLazyCheckRefreshTokenQuery,
  useSignUpMutation,
  useLogOutMutation,
} = authApi;
