import { userApi } from "./userApi";

const orderApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotValidOrder: builder.query({
      query: () => `shopify-api/not-valid-orders`,
      providesTags: ["NotValidOrder"],
    }),
    getAllFulfillmentStatus: builder.query({
      query: () => `shopify-api/get-fulfillment-status`,
    }),
    getOneOrder: builder.query({
      query: (orderId) => `shopify-api/get-one-order-detail/${orderId}`,
      providesTags: ["OrderToEdit"],
    }),
    getOneOrderById: builder.query({
      query: (orderId) => `shopify-api/get-one-order/${orderId}`,
      providesTags: ["SingleOrder"],
    }),
    getProductsOrderTable: builder.query({
      query: ({ orderId }) => `shopify-api/products-order-table/${orderId}`,
      // providesTags: ["OrderToEdit"],
    }),
    updateFulfillmentRequest: builder.mutation({
      query: ({ orders, toFulfill, fulfillmentStatusId }) => ({
        url: `shopify-api/update-fulfill-request`,
        method: "PUT",
        body: { orders, toFulfill, fulfillmentStatusId },
      }),
      invalidatesTags: ["ShopifyOrders"],
    }),
    insertAllByXlsx: builder.mutation({
      query: ({ orders, registeredUserId }) => ({
        url: `shopify-api/insert-by-xlsx`,
        method: "POST",
        body: { orders, registeredUserId },
      }),
    }),
    ignoreOrder: builder.mutation({
      query: (orderId) => ({
        url: `shopify-api/is-ignored`,
        method: "POST",
        body: { orderId },
      }),
      invalidatesTags: [
        //"ShopifyOrders",
        "Shipments",
        "NotValidOrder",
      ],
    }),
    updateOrderShipment: builder.mutation({
      query: ({ orderId, updatedOrderShipment }) => ({
        url: `shopify-api/update-order-shipment`,
        method: "PUT",
        body: { orderId, updatedOrderShipment },
      }),
      invalidatesTags: [
        "OrderToEdit",
        //"ShopifyOrders",
        "Shipments",
        "NotValidOrder",
      ],
    }),
    importAllOrder: builder.mutation({
      query: () => ({
        url: `shopify-api/import-all-order`,
        method: "POST",
        body: {},
      }),
    }),
    updateSkuOrderProduct: builder.mutation({
      query: ({ storeId, orderId, variantId, sku }) => ({
        url: `shopify-api/update-sku-order-product`,
        method: "PUT",
        body: { storeId, orderId, variantId, sku },
      }),
      invalidatesTags: [
        "OrderToEdit",
        //"ShopifyOrders",
        "Shipments",
        "NotValidOrder",
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNotValidOrderQuery,
  useIgnoreOrderMutation,
  useGetProductsOrderTableQuery,
  useGetOneOrderQuery,
  useUpdateOrderShipmentMutation,
  useUpdateFulfillmentRequestMutation,
  useUpdateSkuOrderProductMutation,
  useImportAllOrderMutation,
  useLazyGetOneOrderQuery,
  useGetAllFulfillmentStatusQuery,
  useLazyGetOneOrderByIdQuery,
  useInsertAllByXlsxMutation,
} = orderApi;
