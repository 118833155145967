import React from "react";

function RowTable({ children, className }) {
  return (
    <tr
      className={`shopify-order-row even:bg-white odd:bg-gray-50 ${
        className ?? ""
      }`}
    >
      {children}
    </tr>
  );
}

export default RowTable;
