import { userApi } from "./userApi";

const remittanceApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRemittance: builder.query({
      query: ({ startDate, endDate }) =>
        `remittance/get-all/${startDate}/${endDate}`,
      providesTags: ["Remittance"],
    }),
    getAmountToBePaid: builder.query({
      query: ({ userId }) => `remittance/amount-to-be-paid/${userId}`,
      providesTags: ["Remittance"],
    }),
    getLastRemittanceLoaded: builder.query({
      query: () => `remittance/last-remittance-loaded`,
    }),
    getLastRemittanceCashed: builder.query({
      query: () => `remittance/last-remittance-cashed`,
    }),
    getRemittanceCashedHistory: builder.query({
      query: () => `remittance/remittance-cashed-history`,
    }),
    sendRemittanceFile: builder.mutation({
      query: ({ base64, fileName }) => ({
        url: `remittance/save`,
        method: "POST",
        body: { base64, fileName },
        responseHandler: "text",
        //responseHandler: (response) => response.text(),
      }),
      invalidatesTags: ["Remittance"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllRemittanceQuery,
  useSendRemittanceFileMutation,
  useGetAmountToBePaidQuery,
  useGetLastRemittanceLoadedQuery,
  useGetLastRemittanceCashedQuery,
  useGetRemittanceCashedHistoryQuery,
} = remittanceApi;
