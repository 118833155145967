import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { useSaveTermsAndConditionsMutation } from "../services/termsAndConditions";
import { getPublicClientIp } from "../functions/ip_address";

function PopupTermsAndConditions({ children, open, setOpen, padding }) {
  const [saveTermsAndConditions] = useSaveTermsAndConditionsMutation();
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const containerRef = useRef(null);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToEnd(true);
      }
    }
  };

  const measuredRef = useCallback(
    (node) => {
      containerRef.current = node;
      console.log(containerRef.current);
      var container = containerRef.current;
      if (container && open) {
        container.addEventListener("scroll", handleScroll);
      }
    },
    [open]
  );

  const handleAccept = async () => {
    const publicIpAddress = await getPublicClientIp();
    saveTermsAndConditions({ ipAddress: publicIpAddress }).then((res) => {
      if (res.error) {
        return;
      }
      setOpen(false);
      window.location.reload();
    });
  };

  return (
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={() => {}}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={`max-h-256 max-w-7xl overflow-auto relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${
                  padding == undefined ? "px-4 pb-4 pt-5 sm:p-6" : padding
                } `}
              >
                <div
                  ref={measuredRef}
                  className="max-h-96 max-w-96 overflow-auto"
                  id="container-popup-policy"
                >
                  {children}
                </div>
                <button
                  className={`mt-4 px-4 py-2 bg-green-600 text-white rounded ${
                    isScrolledToEnd
                      ? "opacity-100"
                      : "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={handleAccept}
                  disabled={!isScrolledToEnd}
                >
                  Accetta Termini e Condizioni
                </button>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PopupTermsAndConditions;
