import { RefreshIcon } from "@heroicons/react/outline";

export const LoadingIcon = ({ color, size }) => {
  return (
    <svg
      className={`animate-spinreverse ${size == undefined ? "h-6 w-6" : size} ${
        color !== undefined ? color : "text-green-facile"
      }`}
      viewBox="0 0 24 24"
    >
      <RefreshIcon />
    </svg>
  );
};
