import React from "react";

function BadgeComponent({ background, color, text }) {
  return (
    <span
      className={`inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium shadow-sm  bg-white ${
        color ? `text-${color}-900` : ""
      }  ring-1 ring-inset ring-gray-200`}
    >
      <svg
        className={`h-1.5 w-1.5 ${background ? `fill-${background}-500` : ""}`}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      {text}
    </span>
  );
}

export default BadgeComponent;
