export const NextActionEnum = {
  riconsegna: "riconsegna",
  "riconsegna al nuovo destinatario": "riconsegna al nuovo destinatario",
  "reso al mittente": "reso al mittente",
  distruggere: "distruggere",
  "il destinatario ritira la merce in sede":
    "il destinatario ritira la merce in sede",
};

export const NextActionRadioButtons = [
  { id: 0, name: NextActionEnum.riconsegna },
  {
    id: 1,
    name: NextActionEnum["riconsegna al nuovo destinatario"],
  },
  { id: 2, name: NextActionEnum["reso al mittente"] },
  { id: 3, name: NextActionEnum.distruggere },
  {
    id: 4,
    name: NextActionEnum["il destinatario ritira la merce in sede"],
  },
];
