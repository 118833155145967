import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import { LoginPage } from "./pages/LoginPage";
import RequireAuth from "./auth/RequireAuth";
import { IntegrationPage } from "./pages/IntegrationPage";
import { OrderPage } from "./pages/OrderPage";
import { CatalogPage } from "./pages/CatalogPage";

import { RegistrationPage } from "./pages/RegistrationPage";
import CashFlowPage from "./pages/CashFlowPage";
import SettingsPage from "./pages/SettingsPage";
import { CustomerPage } from "./pages/CustomerPage";
import { CodRemittancePage } from "./pages/CodRemittancePage";
import HomePage from "./pages/HomePage";
import { AccountPage } from "./pages/AccountPage";
import ShopifyOrderDetailPage from "./pages/ShopifyOrderDetailPage";
import ConfirmSubscriptionPage from "./pages/ConfirmSubscriptionPage";
import ErrorSubscriptionPage from "./pages/ErrorSubscriptionPage";
import ConfirmProductsPage from "./pages/ConfirmProductsPage";
import ErrorProductsPage from "./pages/ErrorProductsPage";
import { ShipmentPage } from "./pages/ShipmentPage";
import ManageProductCatalogPage from "./pages/ManageProductCatalogPage";
import { OrderToFulfillPage } from "./pages/OrderToFulfillPage";
import PromotionCodePage from "./pages/PromotionCodePage";
import { OrderToConfirmPage } from "./pages/OrderToConfirmPage";
import TutorialPage from "./pages/TutorialPage,";
import TerminiCondizioniPage from "./pages/TerminiCondizioniPage";
import GoogleTagManager from "./GoogleTagManager";
import FacebookPixel from "./FacebookPixel";
import { ShipmentOnHoldPage } from "./pages/ShipmentOnHoldPage";
import RecoveryPasswordPage from "./pages/RecoveryPasswordPage";
import CheckoutPage from "./pages/CheckoutPage";

function App() {
  return (
    <main>
      <FacebookPixel />
      <GoogleTagManager />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route
            path="termini-e-condizioni"
            element={<TerminiCondizioniPage />}
          />
          <Route
            path="register/:promotionCode?"
            element={<RegistrationPage />}
          />
          <Route
            path="confirm/session/:session_id"
            element={<ConfirmSubscriptionPage />}
          />
          <Route
            path="error/session/:session_id"
            element={<ErrorSubscriptionPage />}
          />
          <Route
            path="confirm/products/session/:session_id"
            element={<ConfirmProductsPage />}
          />
          <Route
            path="error/products/session/:session_id"
            element={<ErrorProductsPage />}
          />
          <Route
            path="recovery-password/:token?"
            element={<RecoveryPasswordPage />}
          />
          {/* <Route path="pricing" element={<PricingPage />} /> */}
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route path="checkout" element={<CheckoutPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route path="integration" element={<IntegrationPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route path="cash-flow" element={<CashFlowPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route path="orders" element={<OrderPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1]} />}>
            <Route path="orders-to-fulfill" element={<OrderToFulfillPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 3]} />}>
            <Route path="orders-to-confirm" element={<OrderToConfirmPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route path="shipment" element={<ShipmentPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2, 3]} />}>
            <Route path="shipment-on-hold" element={<ShipmentOnHoldPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2, 3]} />}>
            <Route path="catalog" element={<CatalogPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route path="settings" element={<SettingsPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route path="cod-remittance" element={<CodRemittancePage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1]} />}>
            <Route path="customers" element={<CustomerPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route path="account" element={<AccountPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route path="tutorials" element={<TutorialPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1, 2, 3]} />}>
            <Route
              path="orders/:orderId"
              element={<ShopifyOrderDetailPage />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1]} />}>
            <Route path="product" element={<ManageProductCatalogPage />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[1]} />}>
            <Route path="promotion" element={<PromotionCodePage />} />
          </Route>
        </Route>
      </Routes>
    </main>
  );
}

export default App;
