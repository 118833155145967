import React from "react";

function HiddenHeaderColTable() {
  return (
    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
      <span className="sr-only">Edit</span>
    </th>
  );
}

export default HiddenHeaderColTable;
