import React from "react";

function RadioGroup({ items, onChange, defaultItem }) {
  return (
    <div className="space-y-6 sm:flex sm:items-center sm:space-x-5 sm:space-y-0 ">
      {items.map((item) => (
        <div
          key={item.id}
          className="flex items-center bg-gray-100 px-3 py-1 rounded-md"
        >
          <input
            onChange={() => onChange(item)}
            id={item.id}
            name="notification-method"
            type="radio"
            defaultChecked={
              defaultItem == undefined
                ? item.id === 0
                : defaultItem.id === item.id
            }
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 "
          />
          <label
            htmlFor={item.id}
            className="ml-1 block text-sm font-medium leading-6 text-gray-900"
          >
            {item.name}
          </label>
        </div>
      ))}
    </div>
  );
}

export default RadioGroup;
