import { userApi } from "./userApi";

const recoveryPasswordApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyEmail: builder.mutation({
      query: ({ email, ipAddress }) => ({
        url: `recovery-password/verify-email`,
        method: "POST",
        body: { email, ipAddress },
      }),
    }),
    changePassword: builder.mutation({
      query: ({ password, token }) => ({
        url: `recovery-password/change-password`,
        method: "POST",
        body: { password, token },
      }),
    }),
    validateToken: builder.query({
      query: (token) => ({
        url: `recovery-password/validate-token/${token}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useVerifyEmailMutation,
  useValidateTokenQuery,
  useChangePasswordMutation,
} = recoveryPasswordApi;
