import { useEffect, useState } from "react";
import { ErrorBanner } from "../components/custom/ErrorBanner";
import { ShipmentCount } from "../components/shipment/ShipmentCount";
import { ListProductsToShip } from "../components/shipment/ProductsToShip/ListProductsToShip";
import { Costs } from "../components/shipment/Costs";
import { Earnings } from "../components/shipment/Earnings";
import { Nett } from "../components/shipment/Nett";
import { useSelector } from "react-redux";
import ListCashFlow from "../components/ListCashFlow";
import DropDown from "../components/order/NewShopifyOrderList/DropDown";
import moment from "moment";
import { useGetAllCustomerQuery } from "../services/customerApi";
import Select from "react-select";
import {
  useGetLastRemittanceCashedQuery,
  useGetLastRemittanceLoadedQuery,
  useGetRemittanceCashedHistoryQuery,
} from "../services/remittanceApi";
import PopupDialog from "../components/custom/PopupDialog";
import ListTable from "../components/custom/ListTable";
import HeaderTable from "../components/custom/HeaderTable";
import RowTable from "../components/custom/RowTable";
import HeaderColTable from "../components/custom/HeaderColTable";
import BodyTable from "../components/custom/BodyTable";
import CellRowTable from "../components/custom/CellRowTable";

const options = [
  { id: 0, name: "Tutto" },
  { id: 1, name: "Spedizione" },
  { id: 2, name: "Rimessa Contrassegno" },
  { id: 3, name: "Bonifico" },
  { id: 4, name: "Prodotti" },
  { id: 5, name: "Pagamento Online" },
  { id: 6, name: "Ricarica" },
  { id: 7, name: "Svincolo" },
];

export default function CashFlowPage() {
  const [startDate, setStartDate] = useState(
    moment().add(-30, "days").format("yyyy-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("yyyy-MM-DD"));
  const [showHistoryRemittancePopup, setShowHistoryRemittancePopup] =
    useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const auth = useSelector((state) => state.auth);
  const [filterCustomer, setFilterCustomer] = useState();
  const [filterType, setFilterType] = useState(options[0]);

  var {
    data: lastRemittanceLoaded,
    isLoading: loadingLastRemittanceLoaded,
    isSuccess: loadedLastRemittanceLoaded,
    isUninitialized: unitializedLastRemittanceLoaded,
  } = useGetLastRemittanceLoadedQuery(undefined, {
    skip: auth.roles.find((role) => role == 1),
  });
  var {
    data: lastRemittanceCashed,
    isLoading: loadingLastRemittanceCashed,
    isSuccess: loadedLastRemittanceCashed,
    isUninitialized: unitializedLastRemittanceCashed,
  } = useGetLastRemittanceCashedQuery(undefined, {
    skip: auth.roles.find((role) => role == 1),
  });

  var {
    data: remittanceCashedHistory,
    isLoading: loadingRemittanceCashedHistory,
    isSuccess: loadedRemittanceCashedHistory,
    isUninitialized: unitializedRemittanceCashedHistory,
  } = useGetRemittanceCashedHistoryQuery(undefined, {
    skip:
      auth.roles.find((role) => role == 1) ||
      showHistoryRemittancePopup == false,
  });

  var {
    customers,
    isLoading: loadingCustomers,
    isSuccess: successCustomers,
    isError: errorCustomersEvent,
    error: errorCustomersText,
  } = useGetAllCustomerQuery("%20", {
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      var customers = data?.map((cus) => ({
        value: cus,
        label: `${cus?.name} ${cus?.surname}`,
      }));
      return {
        customers,
        isLoading,
        isSuccess,
        isError,
        error,
      };
    },
  });
  console.log("remittanceCashedHistory", remittanceCashedHistory);
  return (
    <main className="px-4">
      <div className="mb-4 ">
        <div className="relative isolate">
          {/* Secondary navigation */}
          <header className="pt-6 ">
            <div className="flex-wrap items-center gap-6 sm:flex-nowrap ">
              <div className="flex flex-wrap w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto items-end sm:leading-7">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Da
                  </label>
                  <input
                    className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                    type="date"
                    id="inizio-flusso"
                    value={startDate}
                    max={endDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    A
                  </label>
                  <input
                    className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                    type="date"
                    id="fine-flusso"
                    value={endDate}
                    min={startDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div>
                  {auth.roles.includes(1) ? (
                    <div className="flex-col">
                      <span className="block text-sm font-medium leading-6 text-gray-900">
                        Cliente
                      </span>
                      <Select
                        placeholder={"Cerca Cliente..."}
                        isSearchable={true}
                        options={customers}
                        onChange={setFilterCustomer}
                        isClearable={true}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <DropDown
                    label={"Tipo"}
                    items={options}
                    selectedItem={filterType}
                    onChange={setFilterType}
                  />
                </div>
              </div>
            </div>
          </header>

          {/* Stats */}

          <div>
            <dl
              className={`mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 ${
                auth.roles.find((role) => role == 1)
                  ? "lg:grid-cols-4"
                  : "lg:grid-cols-2"
              } `}
            >
              <ShipmentCount startDate={startDate} endDate={endDate} />
              {auth.roles.find((role) => role == 1) ? (
                <>
                  <Nett startDate={startDate} endDate={endDate} />
                  <Earnings startDate={startDate} endDate={endDate} />
                </>
              ) : (
                <></>
              )}
              <Costs startDate={startDate} endDate={endDate} />
            </dl>
          </div>
          <div className="flex justify-between py-2">
            <PopupDialog
              open={showHistoryRemittancePopup}
              setOpen={setShowHistoryRemittancePopup}
            >
              <ListTable className="mt-2">
                <HeaderTable>
                  <RowTable>
                    <HeaderColTable>Data</HeaderColTable>
                    <HeaderColTable>Importo Incassato</HeaderColTable>
                  </RowTable>
                </HeaderTable>
                <BodyTable>
                  {loadedRemittanceCashedHistory ? (
                    remittanceCashedHistory?.map((remittance, index) => (
                      <RowTable key={index}>
                        <CellRowTable>{remittance.created_date}</CellRowTable>
                        <CellRowTable textAlign={"text-center"}>
                          {remittance.amount}€
                        </CellRowTable>
                      </RowTable>
                    ))
                  ) : (
                    <></>
                  )}
                </BodyTable>
              </ListTable>
            </PopupDialog>
            {loadedLastRemittanceLoaded ? (
              <p className="text-gray-400 text-xs md:text-xl">
                Ultima rimessa caricata:{" "}
                {lastRemittanceLoaded?.createdDate?.split("T")[0] ?? ""}
              </p>
            ) : (
              <></>
            )}
            {loadedLastRemittanceCashed ? (
              <p
                className="text-gray-400 text-xs md:text-xl cursor-pointer"
                onClick={() => setShowHistoryRemittancePopup(true)}
              >
                Incasso da ultima rimessa: {lastRemittanceCashed.amount}€
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        {auth.roles.find((role) => role == 1) ? (
          <ListProductsToShip startDate={startDate} endDate={endDate} />
        ) : (
          <></>
        )}
      </div>
      {/* <ListShipment startDate={startDate} endDate={endDate} /> */}
      <ListCashFlow
        startDate={startDate}
        endDate={endDate}
        customer={filterCustomer?.value}
        filterType={filterType}
      />
      <ErrorBanner
        message={errorMessage}
        setShow={setShowErrorMessage}
        show={showErrorMessage}
      />
    </main>
  );
}
