import { userApi } from "./userApi";

const shippingPriceListWeightRangeApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllByShippingPriceListId: builder.query({
      query: (shippingPriceListId) =>
        `shipping-price-list-weight-range/get-all-by-shipping-price-list-id/${shippingPriceListId}`,
      providesTags: ["ShippingPriceListWeightRange"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetShippingPriceListWeightRangeQuery,
  useGetAllByShippingPriceListIdQuery,
  useAddShippingPriceListWeightRangeMutation,
  useEditShippingPriceListWeightRangeMutation,
  useDeleteShippingPriceListWeightRangeMutation,
} = shippingPriceListWeightRangeApi;
