("use client");

import { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  MinusCircleIcon,
  PlusCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { imagesUrl } from "../../config/baseUrl";
import { useGetShipmentCostProductsMutation } from "../../services/shipmentApi";
import {
  useDeleteOneProductCatalogInCartMutation,
  useEditOneProductCatalogInCartMutation,
} from "../../services/productCatalogInCartApi";
import { Link, useNavigate } from "react-router-dom";
import { usePayProductsInCartMutation } from "../../services/stripeApi";

export default function CartPopup({
  productsCatalogInCart: products,
  open,
  setOpen,
}) {
  const [amount, setAmount] = useState(0);
  const [shipmentCost, setShipmentCost] = useState(0);
  const navigate = useNavigate();
  const [getShipmentCostProducts, { data: shippingPriceListWeightRange }] =
    useGetShipmentCostProductsMutation();

  const [payProductsInCart] = usePayProductsInCartMutation();

  const [deleteOneProductCatalogInCart] =
    useDeleteOneProductCatalogInCartMutation();

  const [editOneProductCatalogInCart] =
    useEditOneProductCatalogInCartMutation();

  useMemo(() => {
    setAmount(
      products?.reduce((accumulator, product) => {
        return (
          accumulator + (product?.listPrice ?? 0) * (product?.quantity ?? 0)
        );
      }, 0)
    );
  }, [products]);

  useMemo(() => {
    setShipmentCost(
      (products?.length ?? 0) == 0
        ? 0
        : shippingPriceListWeightRange?.expressShippingCost ?? 0
    );
  }, [shippingPriceListWeightRange]);

  useEffect(() => {
    getShipmentCostProducts(products);
  }, [products]);

  const onDeleteProductInCartClick = async (productCatalogInCart) => {
    deleteOneProductCatalogInCart(productCatalogInCart).then((res) => {
      if (res.error) {
        return;
      }
    });
  };

  const onChangeProductInCartQuantity = async (productInCart, qty) => {
    editOneProductCatalogInCart({
      ...productInCart,
      quantity: productInCart.quantity + qty,
    }).then((res) => {
      if (res.error) {
        return;
      }
    });
  };

  const onCheckoutClick = () => {
    payProductsInCart({
      productsCatalogInCart: products,
      shipmentCost: shippingPriceListWeightRange?.expressShippingCost ?? 0,
    }).then((res) => {
      if (res.error) {
        return;
      }
      window.location.href = res.data;
    });
  };

  return (
    <Transition show={open} as="div">
      <Dialog open={open} onClose={setOpen} className="relative z-50">
        <TransitionChild
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden h-screen">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex w-screen md:w-fit md:max-w-full md:pl-10">
              <TransitionChild
                as="div"
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen h-screen md:max-w-xs  ">
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-lg font-medium text-gray-900">
                          Carrello
                        </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                          >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close panel</span>
                            <XIcon aria-hidden="true" className="h-6 w-6" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {products?.map((product) => (
                              <li key={product.id} className="flex py-6">
                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                  {(product?.productCatalog
                                    ?.productCatalogImage[0]?.src ?? "") !==
                                    "" && (
                                    <img
                                      alt={product?.productCatalog?.name ?? ""}
                                      src={`${imagesUrl}${
                                        product?.productCatalog
                                          ?.productCatalogImage[0]?.src ?? ""
                                      }`}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  )}
                                </div>

                                <div className="ml-4 flex flex-1 flex-col">
                                  <div>
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                      <h3>
                                        <a href={product.href}>
                                          {product.productCatalog?.name}
                                        </a>
                                      </h3>
                                      <p className="ml-4">
                                        {product.productCatalog?.price}€
                                      </p>
                                    </div>
                                    <p className="mt-1 text-sm text-gray-500">
                                      {product.color}
                                    </p>
                                  </div>
                                  <div className="flex flex-1 items-end justify-between text-sm">
                                    <div className="flex gap-2 items-center">
                                      <PlusCircleIcon
                                        className="h-6 w-6 text-gray-700 cursor-pointer"
                                        onClick={() =>
                                          onChangeProductInCartQuantity(
                                            product,
                                            1
                                          )
                                        }
                                      />
                                      <span className="text-gray-700 text-xl">
                                        {product.quantity}
                                      </span>
                                      <MinusCircleIcon
                                        className="h-6 w-6 text-gray-700 cursor-pointer"
                                        onClick={() =>
                                          onChangeProductInCartQuantity(
                                            product,
                                            -1
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="flex">
                                      <button
                                        onClick={() =>
                                          onDeleteProductInCartClick(product)
                                        }
                                        type="button"
                                        className="font-medium text-green-600 hover:text-green-500"
                                      >
                                        Rimuovi
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Prodotti</p>
                        <p>{amount}€</p>
                      </div>

                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Spedizione</p>
                        <p>{shipmentCost}€</p>
                      </div>
                      <div className="flex justify-between text-base font-medium text-gray-900 mt-3">
                        <p className="text-2xl">Totale</p>
                        <p className="text-2xl">{amount + shipmentCost}€</p>
                      </div>
                      <div className="mt-6">
                        <button
                          onClick={() => onCheckoutClick()}
                          className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700 w-full"
                        >
                          Checkout
                        </button>
                        {/* <Link
                          to="/checkout"
                          className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700"
                        >
                          Checkout
                        </Link> */}
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          o{" "}
                          <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="font-medium text-green-600 hover:text-green-500"
                          >
                            Continua la spesa
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
