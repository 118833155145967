import React from "react";

function PrivacyPolicyText() {
  return (
    <main
      id="content"
      className="site-main post-1839 page type-page status-publish hentry"
    >
      <div className="page-header">
        <h1 className="text-4xl font-bold">Privacy Policy</h1>{" "}
      </div>

      <div className="page-content">
        <p>
          Questa Applicazione raccoglie alcuni <br />
          Dati Personali dei propri Utenti.
        </p>

        <h2 className="text-2xl font-bold" id="purposes_data">
          <br />
          Dati Personali trattati per le seguenti finalità e utilizzando i
          seguenti servizi:
        </h2>

        <ul>
          <li className="list-disc ml-6 mt-2">
            Contattare l’Utente
            <ul>
              <li className="list-disc ml-6 mt-2">
                Contatto via telefono
                <br />
                <br />
                Dati Personali: numero di telefono
              </li>

              <li className="list-disc ml-6 mt-2">
                Mailing list o newsletter
                <br />
                Dati Personali: cognome; Dati di utilizzo; email; nome; numero
                di telefono; sito web; Strumenti di Tracciamento
              </li>

              <li className="list-disc ml-6 mt-2">
                Modulo di contatto
                <br />
                Dati Personali: Codice Fiscale; cognome; data di nascita; Dati
                di utilizzo; email; ID Utente; nome; numero di telefono; Partita
                IVA; ragione sociale; sito web; Strumenti di Tracciamento; varie
                tipologie di Dati
              </li>

              <li className="list-disc ml-6 mt-2">
                Creazione e gestione di questa Applicazione
                <ul>
                  <li className="list-disc ml-6 mt-2">
                    WordPress (self-hosted)
                    <br />
                    Dati Personali: CAP; città; cognome; cronologia acquisti;
                    data di nascita; Dati comunicati durante l’utilizzo del
                    servizio; Dati di utilizzo; email; indirizzo di
                    fatturazione; indirizzo di spedizione; indirizzo fisico;
                    informazioni di contatto; informazioni di pagamento;
                    informazioni sul dispositivo; lingua; nazione; nome; numero
                    di telefono; Partita IVA; password; posizione geografica;
                    ragione sociale; username
                  </li>
                </ul>
              </li>

              <li className="list-disc ml-6 mt-2">
                Gestione contatti e invio di messaggi
                <ul className="wp-block-list">
                  <li className="list-disc ml-6 mt-2">
                    Mailchimp
                    <br />
                    Dati Personali: cognome; Dati comunicati durante l’utilizzo
                    del servizio; email; indirizzo fisico; nazione; nome; numero
                    di telefono; Strumenti di Tracciamento; varie tipologie di
                    Dati
                  </li>
                </ul>
              </li>

              <li className="list-disc ml-6 mt-2">
                Gestione dei pagamenti
                <ul className="wp-block-list">
                  <li className="list-disc ml-6 mt-2">
                    Stripe
                    <br />
                    Dati Personali: cognome; cronologia acquisti; Dati di
                    utilizzo; email; indirizzo di fatturazione; informazioni di
                    pagamento; nome; Strumenti di Tracciamento
                  </li>

                  <li className="list-disc ml-6 mt-2">
                    PayPal
                    <br />
                    Dati Personali: cognome; cronologia acquisti; Dati di
                    utilizzo; email; fuso orario; ID dell’ordine; indirizzo di
                    fatturazione; indirizzo di spedizione; informazioni di
                    pagamento; informazioni sul dispositivo; IP address; nome;
                    ragione sociale; Strumenti di Tracciamento; username; varie
                    tipologie di Dati secondo quanto specificato dalla privacy
                    policy del servizio
                  </li>
                </ul>
              </li>

              <li className="list-disc ml-6 mt-2">
                Gestione dei tag
                <ul className="wp-block-list">
                  <li className="list-disc ml-6 mt-2">
                    Google Tag Manager
                    <br />
                    Dati Personali: Strumenti di Tracciamento
                  </li>
                </ul>
              </li>

              <li className="list-disc ml-6 mt-2">
                Pubblicità
                <ul className="wp-block-list">
                  <li className="list-disc ml-6 mt-2">
                    Monitoraggio conversioni di Meta ads (pixel di Meta),
                    Pubblico simile di Meta, Monitoraggio conversioni di Google
                    Ads e Segmenti di pubblico simili di Google AdsDati
                    Personali: Dati di utilizzo; Strumenti di Tracciamento
                  </li>

                  <li className="list-disc ml-6 mt-2">
                    Monitoraggio conversioni di TikTok
                    <br />
                    Dati Personali: Dati di utilizzo; identificatori univoci di
                    dispositivi per la pubblicità (Google Advertiser ID o
                    identificatore IDFA, per esempio); informazioni sul
                    dispositivo; Strumenti di Tracciamento
                  </li>
                </ul>
              </li>

              <li className="list-disc ml-6 mt-2">
                Remarketing e behavioral targeting
                <ul className="wp-block-list">
                  <li className="list-disc ml-6 mt-2">
                    Pubblico personalizzato di Meta
                    <br />
                    Dati Personali: email; Strumenti di Tracciamento
                  </li>

                  <li className="list-disc ml-6 mt-2">
                    Remarketing TikTok
                    <br />
                    Dati Personali: Dati di utilizzo; identificatori univoci di
                    dispositivi per la pubblicità (Google Advertiser ID o
                    identificatore IDFA, per esempio); informazioni sul
                    dispositivo; Strumenti di Tracciamento
                  </li>

                  <li className="list-disc ml-6 mt-2">
                    Remarketing Google Ads
                    <br />
                    Dati Personali: Dati di utilizzo; Strumenti di Tracciamento
                  </li>
                </ul>
              </li>

              <li className="list-disc ml-6 mt-2">
                Statistica
                <ul className="wp-block-list">
                  <li className="list-disc ml-6 mt-2">
                    Meta Events Manager
                    <br />
                    Dati Personali: clic; cronologia delle ricerche; cronologia
                    di navigazione; Dati comunicati durante l’utilizzo del
                    servizio; Dati di utilizzo; ID dell’ordine; ID Utente;
                    identificatori univoci di dispositivi per la pubblicità
                    (Google Advertiser ID o identificatore IDFA, per esempio);
                    informazioni sul browser; IP address; pageview;
                    sottoscrizioni dell’utente; stato; Strumenti di Tracciamento
                  </li>

                  <li className="list-disc ml-6 mt-2">
                    WordPress Stats
                    <br />
                    Dati Personali: Dati di utilizzo; Strumenti di Tracciamento
                  </li>

                  <li className="list-disc ml-6 mt-2">
                    Google Analytics 4<br />
                    Dati Personali: Dati di utilizzo; numero di Utenti;
                    statistiche delle sessioni; Strumenti di Tracciamento
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>

        <h2 className="text-xl font-bold mt-2" id="opt_out">
          Informazioni su come disattivare gli annunci pubblicitari basati sugli
          interessi
        </h2>

        <p>
          Oltre a qualsiasi funzione di opt-out fornita da uno qualsiasi dei
          servizi elencati in questo documento, gli Utenti possono leggere di
          più su come disattivare gli annunci pubblicitari basati sugli
          interessi nell’apposita sezione della Cookie Policy.
        </p>

        <h2 className="text-xl font-bold mt-2" id="further_data">
          Ulteriori informazioni sul trattamento dei <br />
          Dati Personali
        </h2>

        <ul className="wp-block-list">
          <li className="list-disc ml-6 mt-2">
            Vendita di beni e servizi onlineI <br />
            Dati Personali raccolti sono utilizzati per l’erogazione di servizi
            all’Utente o per la vendita di prodotti, inclusi il pagamento e
            l’eventuale consegna. I Dati Personali raccolti per perfezionare il
            pagamento possono essere quelli relativi alla carta di credito, al
            conto corrente utilizzato per il bonifico o ad altri strumenti di
            pagamento previsti. I Dati di pagamento raccolti da questa
            Applicazione dipendono dal sistema di pagamento utilizzato.
          </li>
        </ul>

        <h2 className="text-xl font-bold mt-2" id="contact_information">
          Informazioni di contatto
        </h2>

        <ul className="wp-block-list">
          <li className="list-disc ml-6 mt-2">
            Titolare del Trattamento dei DatiGVC Group SRL – Via Pergolesi 9,
            Sant’Antimo – Napoli 80029
            <br />
            <strong>Indirizzo email del Titolare:</strong>
            &nbsp;info@codfacile.com
          </li>
        </ul>

        <p>Ultima modifica: 27 giugno 2024</p>
      </div>
    </main>
  );
}

export default PrivacyPolicyText;
