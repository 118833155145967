import { Fragment, useState } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import ListTrackingMovement from "./ListTrackingMovement";

export default function ModalTracking({
  trackingDetails,
  isSuccess,
  isLoading,
  isError,
  error,
  open,
  setOpen,
  trackingNumber,
}) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as="div"
            onClick={() => setOpen(false)}
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          />
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as="div"
            className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <h2 className="mb-2">
              Codice Tracking:{" "}
              <span className="font-bold"> {trackingNumber}</span>
            </h2>
            <br />
            <ListTrackingMovement
              trackingDetails={trackingDetails}
              isSuccess={isSuccess}
              isLoading={isLoading}
              isError={isError}
              error={error}
            />
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
