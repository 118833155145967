import { useEffect, useState } from "react";
import ShopifyKeyForm from "../components/ShopifyKeyForm";

import ShopifyKeyList from "../components/ShopifyKeyList";

export const IntegrationPage = () => {
  return (
    <main className="px-4">
      <ShopifyKeyForm />
    </main>
  );
};
