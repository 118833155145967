import { KeyIcon } from "@heroicons/react/outline";

export default function InputWithIcon({
  onChangeInput,
  label,
  value,
  Icon,
  type = "text",
  placeholder = "",
}) {
  return (
    <div className="mb-5">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          value={value}
          onChange={(e) => onChangeInput(e.target.value)}
          type={type}
          name={type}
          //id={id}
          className="focus:ring-green-facile focus:border-green-facile block w-full pl-10 py-3 sm:text-sm border-gray-300 rounded-md"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
