import { PencilIcon } from "@heroicons/react/outline";
import { useGetAllRemittanceQuery } from "../../services/remittanceApi";
import { LoadingIcon } from "../custom/LoadingIcon";
import { MyDateTime } from "../../functions/date_time";

export const ListCodRemittance = ({ startDate, endDate }) => {
  const {
    data: remittances,
    isLoading: isLoading,
    isSuccess: isSuccess,
    isError: isError,
    error: error,
  } = useGetAllRemittanceQuery({ startDate, endDate });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {isLoading ? (
              <LoadingIcon color={"text-gray-900"} />
            ) : isSuccess ? (
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                      Nr.
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Data
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Totale Contrassegni
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {remittances?.map((remittance, index) => (
                    <tr key={index} className="even:bg-gray-50">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {index + 1}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {MyDateTime.dateToString(remittance.createdDate)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {remittance?.amount?.toFixed(2) ?? 0}
                      </td>
                    </tr>
                  )) ?? <></>}
                </tbody>
              </table>
            ) : isError ? (
              <p>{error}</p>
            ) : (
              <>Si è verificato un errore nel server</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
