import {
  DocumentIcon,
  PhotographIcon,
  UploadIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { readFile } from "xlsx";
import { useSendRemittanceFileMutation } from "../../services/remittanceApi";
import { LoadingIcon } from "../custom/LoadingIcon";

const FileUploader = ({
  selectedFile,
  setSelectedFile,
  handleUpload,
  isLoading,
}) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  return (
    <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)}>
      <div className="col-span-full">
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
          <div className="text-center">
            {selectedFile != null ? (
              <div className="flex gap-3 items-center">
                <span className="text-xl leading-5 text-gray-600">
                  {selectedFile?.name}
                </span>
                <XIcon
                  className="h-4 w-4 text-red-500"
                  onClick={() => setSelectedFile(null)}
                />
              </div>
            ) : (
              <DocumentIcon
                className="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
            )}

            {selectedFile == null ? (
              <>
                <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-green-facile focus-within:outline-none focus-within:ring-2 focus-within:ring-green-facile focus-within:ring-offset-2 hover:text-green-facile"
                  >
                    <span>Carica file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept=".xlsx"
                      className="sr-only"
                      onChange={(e) => handleFileChange(e)}
                    />
                  </label>
                  <p className="pl-1">o trascina qui il file</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  XLSX up to 10MB
                </p>
              </>
            ) : (
              <div className="mt-4 flex justify-center">
                <button
                  onClick={() => handleUpload()}
                  type="button"
                  className="rounded-full flex gap-2 items-center bg-green-facile p-2 px-4 text-white shadow-sm hover:bg-green-facile focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-facile"
                >
                  {isLoading ? (
                    <LoadingIcon color={"text-gray-900"} />
                  ) : (
                    <UploadIcon className="h-6 w-6" aria-hidden="true" />
                  )}
                  <span className="text-bold">Carica sul server</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
